<template>
  <div class="skill-select-all">
    <!-- Header -->
    <div class="header">
      <b-container>
        <b-row>
          <b-col md="5">
            <b-form-input
              class="skill-search"
              type="text"
              placeholder="Search Skills..."
              v-model="search"
            />
          </b-col>
          <b-col md="7">
            <div class="buttons">
              <b-button
                :variant="selectedSkills.length === 0 ? 'outline-secondary' : 'primary'"
                class="mr-10"
                @click="addSelectedSkills"
                :disabled="selectedSkills.length === 0"
              >
                Add Selected Skills ({{ selectedSkills.length }})
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="cancel"
              >
                Cancel
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Content -->
    <b-container>
      <div
        v-for="skill in filteredSkills"
        :key="skill.id"
        class="skill-tag"
        :class="{ selected: isSelected(skill)}"
        @click="toggleSkill(skill)"
      >
        {{ skill.name }} <b-badge v-if="currentYear === new Date(skill.createdAt).getFullYear()" variant="info">NEW</b-badge>
      </div>
    </b-container>
  </div>
</template>

<script>
import { findIndex, sortBy } from "lodash";

export default {
  name: "AppSkillSelectAll",

  props: {
    skills: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      search: "",
      selectedSkills: [],
    };
  },

  computed: {
    filteredSkills() {
      let data = this.skills;

      if (this.search) {
        data = this.skills.filter(skill =>
          skill.name.toLowerCase().match(this.search.toLowerCase()),
        );
      }

      return sortBy(data, skill => skill.name.toLowerCase());
    },
  },

  methods: {
    toggleSkill(skill) {
      if (this.isSelected(skill)) {
        const index = findIndex(this.selectedSkills, { id: skill.id });
        this.selectedSkills.splice(index, 1);
      } else {
        this.selectedSkills.push(skill);
      }
    },

    isSelected(skill) {
      return this.selectedSkills.some(
        selectedSkill => selectedSkill.id === skill.id,
      );
    },

    addSelectedSkills() {
      this.$bvModal.hide("allSkillsModal");
      this.$emit("skills-added", this.selectedSkills);
      this.selectedSkills = [];
    },

    cancel() {
      this.selectedSkills = [];
      this.$bvModal.hide("allSkillsModal");
    },
  },
};
</script>

<style lang="scss">
.skill-select-all {
  .header {
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $white;
    border-bottom: 1px solid $gray-200;
    position: sticky;
    top: 0;

    .buttons {
      @include media-breakpoint-down(sm) {
        display: flex;
        margin-top: 0.75rem;
        justify-content: space-between;
      }
    }
  }
}

.skill-tag {
  display: inline-block;
  color: $gray-600;
  font-size: $font-size-base;
  background-color: $white;
  border: 1px solid $gray-300;
  border-radius: 100px;
  padding: 0.3125rem 1rem;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  user-select: none;
  cursor: pointer;

  &.selected {
    color: $white;
    background-color: theme-color("success");
    border-color: theme-color("success");
  }
}
</style>
