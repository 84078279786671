import Onboarding from "@/views/onboarding/index";
import LoginForm from "@/views/auth/LoginForm";
import ForgotPasswordForm from "@/views/auth/ForgotPasswordForm";

export default [
  {
    path: "/introduction/:id/",
    name: "Onboarding",
    component: Onboarding,
    meta: { public: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginForm,
    meta: { public: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordForm,
    meta: { public: true },
  },
];
