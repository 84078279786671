import SET_ONBOARDING_STEP from "@/state/mutations/SetOnboardingStep.graphql";

export default {
  methods: {
    toStep(componentName) {
      this.$apollo.mutate({
        mutation: SET_ONBOARDING_STEP,
        variables: {
          stepName: componentName,
        },
      });
    },
  },
};
