<template>
  <b-button
    size="sm"
    :variant="button.variant"
    :to="to"
  >
    {{ button.label }}
  </b-button>
</template>

<script>
export default {
  name: "JobListItemButton",

  props: {
    to: {
      type: [Object, String],
      required: true,
    },

    actionRequired: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    button() {
      if (this.actionRequired) {
        return {
          variant: "outline-primary",
          label: "Action Required",
        };
      }

      return {
        variant: "outline-secondary",
        label: "View More",
      };
    },
  },
};
</script>
