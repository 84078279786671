<template>
  <div id="app">
    <notifications
      classes="toast-notification"
      position="bottom left"
      :duration="3500"
    />

    <router-view/>
  </div>
</template>

<script>
import IS_AUTHENTICATED from "@/state/queries/IsAuthenticated.graphql";
import auth from "@/common/auth/authMixin";
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";

export default {
  name: "App",
  mixins: [auth],
  data() {
    return {
      isAuthenticated: false,
    };
  },

  created() {
    this.$apollo.addSmartQuery("isAuthenticated", {
      query: IS_AUTHENTICATED,
    });
  },

  apollo: {
    currentUser: {
      query: CURRENT_USER,
      error(error) {
        if (error.message === "GraphQL error: You do not have permission to perform this action") {
          return this.logout();
        }
        
          throw error;
        
      },
      skip() {
        return !this.isAuthenticated;
      },
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
