<template>
  <b-modal
    ref="deleteSkillModal"
    title="Remove Skill"
    ok-title="Remove"
    no-fade
    @ok="onOk"
  >
    Do you really want to remove <strong>{{ skill.name }}</strong> from your skill list?
  </b-modal>
</template>

<script>
export default {
  name: "DeleteSkillModal",

  data() {
    return {
      skill: {},
    };
  },

  methods: {
    show(skill) {
      this.skill = skill;
      this.$refs.deleteSkillModal.show();
    },

    onOk() {
      this.$emit("ok", this.skill);
    },
  },
};
</script>
