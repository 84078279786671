<template>
  <b-form-select
    v-model="level"
    :options="options"
    :size="size"
    @input="onInput"
  />
</template>

<script>
import ALL_SKILL_LEVELS from "@/graphql/queries/AllSkillLevels.graphql";

export default {
  name: "AppSkillLevelSelect",

  props: {
    value: Number,

    size: String,
  },

  data() {
    return {
      level: this.value,
      options: [],
    };
  },

  apollo: {
    allSkillLevels: {
      query: ALL_SKILL_LEVELS,

      update({ allSkillLevels }) {
        this.options = allSkillLevels.map(level => ({
          value: level.id,
          text: level.name,
        }));

        return allSkillLevels;
      },
    },
  },

  methods: {
    onInput() {
      this.$emit("input", this.level);
    },
  },
};
</script>
