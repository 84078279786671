<template>
  <test-locked
    v-if="locked"
    :on-unlock="onUnlock"
    :loading="loading"
  />
  <test-unlocked
    v-else
    :on-generate-test="onGenerateTest"
    :loading="loading"
  />
</template>

<script>
import TestLocked from "./TestLocked";
import TestUnlocked from "./TestUnlocked";

export default {
  name: "TestNotAvailable",

  components: {
    TestLocked,
    TestUnlocked
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    locked: {
      type: Boolean,
      required: true
    },

    onUnlock: {
      required: true
    },

    onGenerateTest: {
      required: true
    }
  },
};
</script>
