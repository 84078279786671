<template>
  <app-onboarding
    stepNumber="3"
    totalSteps="5"
    title="Skills"
    desc="Please add all your skills by specifying the level of expertise for each of them.
    This is very important for us as it makes it easier to find a good match between a candidate and an employer. "
  >
    <b-form
      novalidate
      @submit.prevent="toStep('Password')"
    >
      <legend class="col-form-label">Skills</legend>
      <div class="onboarding-select-skills">
        <b-form-group>
          <app-skill-select
            ref="skillSelect"
            :skills="skills"
            size="lg"
            @select="addSkill"
          />
        </b-form-group>

        <b-button
          variant="primary"
          size="lg"
          @click="$bvModal.show('allSkillsModal')"
        >
          Show All Skills
        </b-button>
      </div>

      <div class="d-flex flex-column-reverse">
        <b-alert
          v-if="user.candidateprofile.skills.length === 0"
          variant="info"
          class="onboarding-skills-placeholder p-15 mt-20"
          show
        >
          Selected skills will be displayed here. Make sure to select all your skills so we can match you with more potential job offers.
        </b-alert>
        <app-skill-selected
          v-for="skill in user.candidateprofile.skills"
          :key="skill.id"
          :skill="skill"
          @remove="removeSkill"
          @select="onSkillLevelSelect"
        >
          {{ skill.name }}
        </app-skill-selected>
      </div>

      <div class="d-flex flex-row-reverse justify-content-between mt-50">
        <b-button
          type="submit"
          variant="primary"
          size="lg"
        >
            Continue
        </b-button>

        <b-button
          variant="link"
          class="text-muted"
          @click="toStep('SocialLinks')"
        >
          &larr; Back
        </b-button>
      </div>
    </b-form>

    <b-modal
      id="allSkillsModal"
      class="modal-full-screen all-skills-modal"
      hide-header
      hide-footer
      hide-header-close
      no-fade
    >
      <app-skill-select-all
        :skills="skills"
        @skills-added="addSkills"
      />
    </b-modal>
  </app-onboarding>
</template>

<script>
import ALL_SKILLS from "@/graphql/queries/AllSkills.graphql";
import AppOnboarding from "@/components/AppOnboarding";
import AppSkillSelect from "@/components/AppSkillSelect";
import AppSkillSelectAll from "@/components/AppSkillSelectAll";
import AppSkillSelected from "@/components/AppSkillSelected";
import { sortBy } from "lodash";

import onboardingMixin from "./onboardingMixin";

export default {
  name: "Skills",

  components: {
    AppOnboarding,
    AppSkillSelect,
    AppSkillSelectAll,
    AppSkillSelected,
  },

  mixins: [onboardingMixin],

  data() {
    return {
      allSkills: [],
      user: {
        candidateprofile: {
          skills: [],
        },
      },
    };
  },

  computed: {
    skills() {
      const selectedSkillIds = this.user.candidateprofile.skills.map(skill =>
        parseInt(skill.id, 10),
      );

      const filteredSkills = this.allSkills.filter(
        skill => !selectedSkillIds.includes(parseInt(skill.id, 10)),
      );

      return sortBy(filteredSkills, skill => skill.name.toLowerCase());
    },
  },

  apollo: {
    allSkills: {
      query: ALL_SKILLS,
    },
  },

  methods: {
    addSkill(skill) {
      const newSkill = {
        id: parseInt(skill.id, 10),
        name: skill.name,
        level: 1,
      };

      this.user.candidateprofile.skills.push(newSkill);

      this.updateSkills(this.user);
    },

    addSkills(skills) {
      const newSkills = skills.map(skill => ({
        id: parseInt(skill.id, 10),
        name: skill.name,
        level: 1,
      }));

      this.user.candidateprofile.skills = [
        ...this.user.candidateprofile.skills,
        ...newSkills,
      ];

      this.updateSkills(this.user);
    },

    removeSkill(skill) {
      const index = this.user.candidateprofile.skills.indexOf(skill);

      this.user.candidateprofile.skills.splice(index, 1);
      this.updateSkills(this.user);
    },

    onSkillLevelSelect() {
      this.updateSkills(this.user);
    },

    updateSkills(data) {
      this.$emit("skill-update", data);
    }
  },
};
</script>

<style lang="scss">
.all-skills-modal {
  .modal-body {
    padding: 0;
    background-color: $gray-100;
  }
}

.onboarding-select-skills {
  width: 100%;
  display: grid;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 10rem;

    .btn {
      margin-left: 0.5rem;
    }
  }
}

.onboarding-skills-placeholder {
  font-size: $font-size-base;
}
</style>
