<template>
  <b-form
    @submit.prevent="submit"
    novalidate
  >
    <!-- First Name -->
    <b-form-group label="First Name">
      <b-form-input
        :class="{ 'is-invalid': $v.user.firstName.$error }"
        id="firstName"
        placeholder="First Name"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.firstName"
        v-focus
        required
      />
      <b-form-invalid-feedback>
        <div v-if="!$v.user.firstName.required">
          First name is required
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- Last Name -->
    <b-form-group label="Last Name">
      <b-form-input
        :class="{ 'is-invalid': $v.user.lastName.$error }"
        id="lastName"
        placeholder="Last Name"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.lastName"
        required
      />
      <b-form-invalid-feedback>
        <div v-if="!$v.user.lastName.required">
          Last name is required
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- Email -->
    <label class="col-form-label">Email</label>
    <b-form-group :class="{ loading: checkingEmail }">
      <b-form-input
        :class="{ 'is-invalid': $v.user.email.$error || emailRegistered || emailError  }"
        id="email"
        placeholder="Email"
        type="email"
        :size="formFieldSize"
        v-model.trim="user.email"
        @input="checkEmail"
        required
      />
      <b-form-invalid-feedback>
        <div v-if="!$v.user.email.required">
          Email is required
        </div>
        <div v-if="!$v.user.email.email">
          Please enter a valid email address
        </div>
        <div v-if="emailRegistered || emailError">
          {{ emailError }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- Birthdate -->
    <b-form-group label="Birth Date">
      <b-form-input
        id="birthdate"
        placeholder="YYYY-MM-DD"
        type="date"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.birthdate"
      />
    </b-form-group>

    <!-- Location -->
    <b-form-group label="Location">
      <app-country-select
        v-model="user.candidateprofile.location"
        :size="formFieldSize"
      />
    </b-form-group>

    <!-- Nationality -->
    <b-form-group label="Nationality">
      <app-country-select
        :nationality="true"
        v-model="user.candidateprofile.nationality"
        :size="formFieldSize"
      />
    </b-form-group>

    <!-- Area of Expertise -->
    <b-form-group label="Area of Expertise">
      <app-candidate-title-select
        :class="{ 'is-invalid': $v.user.candidateprofile.title.$error }"
        v-model="user.candidateprofile.title"
        :size="formFieldSize"
        required
      />
      <b-form-invalid-feedback>
        <div v-if="!$v.user.candidateprofile.title.required">
          Please select an expertise
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <div
      class="button-container flex-row-reverse justify-content-between mt-50"
      :class="{'d-block': !hasBackButton, 'd-flex': hasBackButton }"
    >
       <!-- Submit Button -->
      <b-button
        :class="{ 'btn-w-120': !hasBackButton, loading: $parent.loading }"
        type="submit"
        variant="primary"
        :size="buttonSize"
        :disabled="$parent.loading"
      >
          {{ buttonLabel }}
      </b-button>

       <!-- Back Button -->
      <b-button
        v-if="hasBackButton"
        variant="link"
        class="text-muted"
        @click="onGoBack"
      >
        &larr; Back
      </b-button>
    </div>
  </b-form>
</template>

<script>
import EMAIL_REGISTERED from "@/graphql/queries/EmailRegistered.graphql";
import { email, required } from "vuelidate/lib/validators";
import { debounce } from "lodash";
import AppCountrySelect from "@/components/AppCountrySelect";
import AppCandidateTitleSelect from "@/components/AppCandidateTitleSelect";
import AppDatepicker from "@/components/AppDatepicker";

export default {
  name: "AppUserProfileForm",

  components: {
    AppCountrySelect,
    AppCandidateTitleSelect,
    AppDatepicker,
  },

  props: {
    userProfile: {
      type: Object,
      required: true,
    },

    hasBackButton: {
      type: Boolean,
      default: true,
    },

    buttonLabel: {
      type: String,
      default: "Continue",
    },

    formFieldSize: {
      type: String,
      default: "lg",
    },

    buttonSize: {
      type: String,
      default: "lg",
    },
  },

  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        candidateprofile: {
          image: "",
          birthdate: null,
          location: null,
          nationality: null,
          title: null,
        },
      },
      emailRegistered: false,
      checkingEmail: false,
      emailError: "",
      success: false,
    };
  },

  created() {
    this.populateData();
  },

  methods: {
    populateData() {
      Object.keys(this.user).forEach(key => {
        if (typeof this.user[key] === "string") {
          this.user[key] = this.userProfile[key];
        } else if (typeof this.user[key] === "object") {
          Object.keys(this.user[key]).forEach(k => {
            // if data is remote we'll check id field for
            // location, nationality and title
            if (this.userProfile[key][k] && this.userProfile[key][k].id) {
              this.user[key][k] = this.userProfile[key][k].id;
            } else {
              // otherwise data is from localStorage
              this.user[key][k] = this.userProfile[key][k];
            }
          });
        }
      });
    },

    onGoBack() {
      this.$emit("back-button-clicked");
    },

    submit() {
      this.$v.user.$touch();

      if (this.$v.user.$invalid || this.emailRegistered || this.emailError) {
        return;
      }

      // Vue changes null to empty string if input field is empty.
      // We make sure the birthdate is null if empty to avoid GraphQL errors.
      if (!this.user.candidateprofile.birthdate) {
        this.user.candidateprofile.birthdate = null;
      }

      this.$emit("form-submitted", this.user);
    },

    // eslint-disable-next-line
    checkEmail: debounce(function() {
      this.$v.user.email.$touch();

      if (this.user.email && this.$v.user.email.email) {
        this.success = false;
        this.checkingEmail = true;
        this.emailError = "";

        this.$apollo.addSmartQuery("emailRegistered", {
          query: EMAIL_REGISTERED,

          variables: {
            email: this.user.email,
          },

          update({ emailRegistered }) {
            this.checkingEmail = false;

            if (emailRegistered) {
              this.emailError = "This email is already registered";

              return true;
            }

            this.success = true;

            return false;
          },
        });
      }
    }, 600),
  },

  validations: {
    user: {
      firstName: {
        required,
      },

      lastName: {
        required,
      },

      email: {
        required,
        email,
      },

      candidateprofile: {
        title: {
          required,
        },
      },
    },
  },
};
</script>
