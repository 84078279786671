<template>
  <div class="logical-ability-test-result my-30 my-lg-50 pb-lg-40">
    <div class="score-container">
        <div class="test-title text-muted mb-10">{{ test.name }}</div>

        <div class="test-score-with-progress">
          <span class="score pr-15 mb-30" :class="`text-${variant}`">{{ test.score }}</span>

          <b-progress
            class="test-progress"
            :variant="variant"
            height="5px"
            :value="test.score"
            :max="100"
          />
        </div>
    </div>

    <div>
      <h3 class="mb-20">{{ test.name }}</h3>
      <p class="score-text">{{ test.text | interpolateResultText(user.fullName, user.firstName, test.score) }}</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "LogicalTestResult",

  props: {
    test: {
      type: Object,
    },

    variant: String,

    user: Object,
  },
};
</script>

<style lang="scss">
.logical-ability-test-result {
  display: grid;
  border-bottom: 1px solid $gray-200;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 12rem 1fr;
    grid-gap: 2rem;
  }

  &:last-child {
    border-bottom: none;
  }
}

.test-score-with-progress {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;

  .score {
    font-size: 1.5rem;
  }
}
</style>
