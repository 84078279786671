<template>
  <app-onboarding
    stepNumber="5"
    totalSteps="5"
  >
    <app-icon
      class="icon-success text-info mb-50"
      name="icon-success"
      width="80"
      height="80"
    />
    <h3 class="mb-40">You Have Successfully Registered!</h3>

    <p class="text-muted">
      Thank you for completing the registration process with 10Xrecruit.
      You can go to dashboard now and start using our services.
    </p>

    <p class="text-muted">
      You can edit all the data you entered during your registration using your dashboard.
    </p>

    <div class="d-flex justify-content-end mt-50">
      <b-button
        type="submit"
        variant="primary"
        size="lg"
        @click="goToDashboard"
        exact
      >
          Go to Dashboard
      </b-button>
    </div>
  </app-onboarding>
</template>

<script>
import AppOnboarding from "@/components/AppOnboarding";
import AppIcon from "@/components/AppIcon";

export default {
  name: "OnboardingFinal",

  components: {
    AppOnboarding,
    AppIcon,
  },

  methods: {
    goToDashboard() {
      this.$router.replace({ name: "Profile" });
    },
  },
};
</script>

<style lang="scss">
.icon-success {
  @include media-breakpoint-down(md) {
    margin-top: -2rem;
  }
}
</style>
