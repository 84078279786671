<template>
  <div
    :style="{ minWidth: size }"
    class="chart-semi-circle-gauge"
  >
    <svg viewBox="0 0 40 20"
      :style="{ maxHeight: size }"
    >
      <!-- Gauge BG -->
      <path
        class="semi-circle-gauge-track"
        :style="trackStyles"
        d="M3,19.92C3,11.12,10.12,4,18.92,4s15.92,7.12,15.92,15.92"
      />

      <!-- Gauge Front -->
      <path
        class="semi-circle-gauge"
        :style="gaugeStyles"
        :stroke-dasharray="`${value / 2} 50`"
        d="M3,19.92C3,11.12,10.12,4,18.92,4s15.92,7.12,15.92,15.92"
      />

      <!-- Text -->
      <text
        class="semi-circle-gauge-text"
        :style="fontStyles"
        x="20"
        y="20"
      >
        {{ value}}%
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "SemiCircleGauge",

  props: {
    color: {
      type: String,
      default: "#82af2b",
    },

    trackColor: {
      type: String,
      default: "#d6dbe5",
    },

    strokeWidth: {
      type: Number,
      default: 5,
    },

    value: {
      type: Number,
      required: true,
      default: 80,
    },

    fontColor: {
      type: String,
      default: "#606c82",
    },

    fontSize: {
      type: String,
      default: "0.4em",
    },

    size: {
      type: String,
      default: "120px",
    },
  },

  computed: {
    gaugeStyles() {
      return {
        stroke: this.color,
        strokeWidth: this.strokeWidth,
      };
    },

    trackStyles() {
      return {
        stroke: this.trackColor,
        strokeWidth: this.strokeWidth,
      };
    },

    fontStyles() {
      return {
        fill: this.fontColor,
        fontSize: this.fontSize,
      };
    },
  },
};
</script>

<style lang="scss">
.semi-circle-gauge-track {
  fill: none;
}

.semi-circle-gauge {
  fill: none;
  animation: chartProgress 0.75s ease-out forwards;
}

.semi-circle-gauge-text {
  text-anchor: middle;
}

@keyframes chartProgress {
  0% {
    stroke-dasharray: 0 100;
  }
}
</style>
