import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import { apolloProvider } from "@/common/plugins/apollo";

export function checkAuth(to, from, next, isAuthenticated) {
  const requiresAuth = to.matched.some(route => !route.meta.public);
  if (requiresAuth && !isAuthenticated) {
    next({ name: "Login", query: { redirect: to.path } });
  } else {
    next();
  }
}

export function checkPageAllowedWhenAuth(to, from, next, isAuthenticated) { 
  if(isAuthenticated) {
    const notAllowedRoutesWhenAuth = [
      {name: "Login", redirect: "Profile"}
    ];
    const notAllowedRoute = notAllowedRoutesWhenAuth.find( ele => ele.name === to.name );  
    
    if(notAllowedRoute) { 
     return next({name: notAllowedRoute.redirect})
    }
  }

  next();
}

export async function hasOnboarded(to, from, next) {
  const apolloClient = apolloProvider.defaultClient;
  const  data =  await apolloClient.query({query:CURRENT_USER});
  const {currentUser, currentUser: {candidateprofile} } = data.data;

  if(!candidateprofile.onboarded) {
    return next({ path: `/introduction/${currentUser.uuid}`})
  }

  next();
}
