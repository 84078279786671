<template>
  <footer class="site-footer text-center text-muted small">
    <p>&copy; {{ currentYear }} 10X People AB | <a href="http://10Xrecruit.com">10Xrecruit.com</a></p>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
.site-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid $gray-200;

  p {
    margin: 0;
  }
}
</style>
