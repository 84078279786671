import Vue from "vue";

import registerPlugins, { apolloProvider } from "@/common/plugins";
import registerDirectives from "@/common/directives";
import AuthService from "@/common/auth/AuthService";
import App from "./App";
import router from "./router";
import "./common/filters";

// Set the authentication status in local state
AuthService.setAuthStatus();

registerPlugins();
registerDirectives();

Vue.config.productionTip = false;

// Creates an SVG sprite and injects it into body
const files = require.context(
  "!svg-sprite-loader!./assets/svg",
  false,
  /.*\.svg$/,
);

files.keys().forEach(files);

new Vue({
  router,
  provide: apolloProvider.provide(),
  render: h => h(App),
}).$mount("#app");
