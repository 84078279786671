import Vue from "vue";
import Router from "vue-router";
import AuthService from "@/common/auth/AuthService";
import NotFound from "@/views/NotFound";
import APP_ERROR from "@/state/queries/AppError.graphql";
import UPDATE_APP_ERROR from "@/state/mutations/UpdateAppError.graphql";
import { apolloProvider } from "@/common/plugins/apollo";
import auth from "./auth";
import dashboard from "./dashboard";
import { checkAuth, checkPageAllowedWhenAuth, hasOnboarded } from "./navigationGuards";

Vue.use(Router);

const removeAppError = () => {
  const apolloClient = apolloProvider.defaultClient;

  const appError = apolloClient.query({ query: APP_ERROR });

  if (appError) {
    apolloClient.mutate({
      mutation: UPDATE_APP_ERROR,

      variables: { message: false },
    });
  }
}

const router = new Router({
  mode: "history",
  routes: [
    ...auth,
    ...dashboard,
    {
      path: "*",
      name: "NotFound",
      component: NotFound,
      meta: { public: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = AuthService.isAuthenticated();
  removeAppError();
  checkAuth(to, from, next, isAuthenticated);
  if (to.name !== "Onboarding" && to.name !== "Login") {
    hasOnboarded(to, from, next);
  }
  checkPageAllowedWhenAuth(to, from, next, isAuthenticated);
});

export default router;
