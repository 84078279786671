<template>
  <transition
    enter-active-class="animated page-enter"
    leave-active-class="animated page-leave"

  >
    <div v-if="question" class="test-question">
      <!-- Question -->
      <vue-markdown-it :source="question.question" id="some-id" class="lead mb-30"/>

      <!-- Question Image -->
      <img
        v-if="question.image"
        class="img-fluid my-20"
        :src="question.image"
        alt="Question Image"
      />

      <!-- Choices -->
      <div
        class="choices"
        :class="[result]"
      >
        <div
          class="choice text-lg mb-10"
          v-for="choice in question.choices"
          :key="choice.character"
          :class="choiceClases(choice)"
          @click="onSelectAnswer(choice)"
        >
          <div class="character">{{ choice.character }}</div>

          <vue-markdown-it :source="choice.text" class="pl-15 pr-40 py-5"/>

          <app-icon
            v-if="selectedChoice && selectedChoice === choice.character"
            :name="iconName"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VueMarkdownIt from "vue-markdown-it";
import AppIcon from "@/components/AppIcon";
import "highlight.js/styles/default.css";

export default {
  name: "TestQuestion",

  components: {
    AppIcon,
    VueMarkdownIt
  },

  props: {
    question: {
      type: [Object, String],
      required: true,
    },

    correctChoice: {
      type: String,
      required: true,
    },

    selectedChoice: {
      type: String,
      required: true,
    },

    result: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconName() {
      return this.result === "success" ? "icon-checkmark" : "icon-close";
    },
  },

  methods: {
    onSelectAnswer(choice) {
      this.$emit("select", choice);
    },

    choiceClases(choice) {
      return {
        correct: choice.character === this.correctChoice,
        wrong:
          this.selectedChoice &&
          choice.character === this.selectedChoice &&
          choice.character !== this.correctChoice,
      };
    },
  },
};
</script>

<style lang="scss">
.test-question {
  .choices {
    .choice {
      position: relative;
      min-height: 3rem;
      display: grid;
      grid-template-columns: 3rem 1fr;
      align-items: center;
      border: 1px solid $gray-300;
      border-radius: $border-radius;
      cursor: pointer;
      transition: $transition-base;
      user-select: none;

      &:hover {
        border-color: $gray-500;
      }

      .character {
        height: 100%;
        text-align: center;
        border-right: 1px solid $gray-300;
        line-height: 2.875rem;
      }

      &.wrong {
        color: darken(theme-color("danger"), 15%);
        background-color: rgba(theme-color("danger"), 0.1);
        border-color: rgba(theme-color("danger"), 0.6);

        .character {
          border-color: rgba(theme-color("danger"), 0.5);
        }
      }

      &.correct {
        color: darken(theme-color("success"), 10%);
        background-color: rgba(theme-color("success"), 0.1);
        border-color: rgba(theme-color("success"), 0.75);

        .character {
          border-color: rgba(theme-color("success"), 0.65);
        }
      }

      .icon {
        position: absolute;
        top: 1rem;
        right: 0.75rem;
      }
    }

    &.error {
      .correct {
        background-color: $white;
        border-color: rgba(theme-color("success"), 0.75);

        .character {
          border-color: rgba(theme-color("success"), 0.75);
        }
      }
    }
  }
}
</style>
