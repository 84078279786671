<template>
  <b-card
    class="user-profile"
  >
    <div
      v-if="!currentUser"
      class="d-flex justify-content-center my-40"
    >
      <app-spinner />
    </div>

    <div v-else>
      <user-avatar
        class="mb-30"
        :image="currentUser.candidateprofile.image"
        :firstName="currentUser.firstName"
        :lastName="currentUser.lastName"
        @user-avatar-selected="onAvatarUpdated"
      />

      <b-row>
        <b-col lg="5">
          <app-user-profile-form
            :user-profile="currentUser"
            :has-back-button="false"
            button-label="Save"
            button-size=""
            formFieldSize=""
            @form-submitted="save"
          />
        </b-col>
      </b-row>
    </div>

  </b-card>
</template>

<script>
import decode from "jwt-decode";
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import user from "@/common/mixins/user";
import auth from "@/common/auth/authMixin";
import AppSpinner from "@/components/AppSpinner";
import AppUserProfileForm from "@/components/AppUserProfileForm";
import UserAvatar from "./UserAvatar";

export default {
  name: "Profile",

  components: {
    AppSpinner,
    AppUserProfileForm,
    UserAvatar,
  },

  mixins: [user, auth],

  data() {
    return {
      newAvatar: "",
      isAvatarUpdated: false,
    };
  },

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "cache-only",
    },
  },

  methods: {
    async save(userData) {
      if (this.isAvatarUpdated) {
        // eslint-disable-next-line
        userData.candidateprofile.image = this.newAvatar;
      }

      const user = await this.updateUser(userData);
      user.email !== decode(localStorage.getItem("accessToken")).username &&
        this.logout();
    },

    onAvatarUpdated(image) {
      this.newAvatar = image;
      this.isAvatarUpdated = true;
    },
  },
};
</script>
