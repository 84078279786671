<template>
  <app-onboarding
    stepNumber="4"
    totalSteps="5"
    title="Choose a Password"
    desc="Enter a password that you will use together with your email to sign in to our platform.
    Please select a strong password with at least 6 characters."
  >
    <b-form
      @submit.prevent="submit"
      novalidate
    >
      <b-form-group label="Password">
        <b-form-input
          :class="{ 'is-invalid': $v.form.password.$error }"
          id="password"
          placeholder="Enter a Password"
          type="password"
          size="lg"
          v-model.trim="form.password"
          v-focus
          required
        />
        <b-form-text>Enter a strong password with at least 6 characters</b-form-text>
        <b-form-invalid-feedback>
          <div v-if="!$v.form.password.required">
            Password is required
          </div>
          <div v-if="!$v.form.password.minLength">
            Password should be min {{ $v.form.password.$params.minLength.min }} characters
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Confirm Password">
        <b-form-input
          :class="{ 'is-invalid': $v.form.confirmPassword.$error }"
          id="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          size="lg"
          v-model="form.confirmPassword"
          required
        />
        <b-form-invalid-feedback v-if="!$v.form.confirmPassword.sameAsPassword">
          Passwords do not match
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          :class="{ 'is-invalid': $v.form.tos.$invalid }"
          :value="true"
          v-model="form.tos"
          required
        >
          Check here to indicate that you have read and agree to the
          <a href="https://app.10xrecruit.com/static/docs/terms_of_service.html" target="_blank">terms of service</a> and
          <a href="https://app.10xrecruit.com/static/docs/privacy_policy.html" target="_blank">privacy policy</a>.
        </b-form-checkbox>
        <b-form-invalid-feedback v-if="$v.form.tos.$invalid">
          Please check to proceed.
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex flex-row-reverse justify-content-between mt-50">
        <b-button
          :class="{ loading }"
          type="submit"
          variant="primary"
          size="lg"
          :disabled="loading"
        >
            Continue
        </b-button>

        <b-button
          variant="link"
          class="text-muted"
          @click="toStep('Skills')"
        >
          &larr; Back
        </b-button>
      </div>
    </b-form>
  </app-onboarding>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import AppOnboarding from "@/components/AppOnboarding";
import auth from "@/common/auth/authMixin";
import onboardingMixin from "./onboardingMixin";

export default {
  name: "Password",

  components: {
    AppOnboarding,
  },

  mixins: [auth, onboardingMixin],

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      user: this.userData,
      form: {
        password: "",
        confirmPassword: "",
        tos: true,
      },
    };
  },

  methods: {
    async submit() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      this.user.password = this.form.password;

      const uuid = this.$route.params.id;

      const isSuccess = await this.register(uuid, this.user);

      if (isSuccess) {
        this.toStep("OnboardingFinal");
      }
    },
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },

      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },

      tos: {
        isChecked: sameAs(() => true),
      },
    },
  },
};
</script>


<style lang="scss" scoped>
.custom-checkbox.is-invalid {
  & ~ .invalid-feedback {
    display: block;
    padding: 1rem 0 0 1.5625rem;
  }
}
</style>
