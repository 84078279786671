<template>
  <b-card>
    <b-row>
      <b-col lg="5">
        <b-form
          @submit.prevent="submit"
          novalidate
        >
          <b-form-group label="Old Password">
            <b-form-input
              :class="{ 'is-invalid': $v.form.oldPassword.$error || error }"
              id="oldPassword"
              placeholder="Your old password"
              type="password"
              v-model.trim="form.oldPassword"
              v-focus
              required
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.oldPassword.required">
                Please enter your old password
              </div>
              <div v-if="error">
                {{ error }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="New Password">
            <b-form-input
              :class="{ 'is-invalid': $v.form.newPassword.$error }"
              id="newPassword"
              placeholder="Your new password"
              type="password"
              v-model.trim="form.newPassword"
              required
            />
            <b-form-text>Enter a strong password with at least 6 characters</b-form-text>
            <b-form-invalid-feedback>
              <div v-if="!$v.form.newPassword.required">
                Please enter your new password
              </div>
              <div v-if="!$v.form.newPassword.minLength">
                Password should be min {{ $v.form.newPassword.$params.minLength.min }} characters
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Confirm Password">
            <b-form-input
              :class="{ 'is-invalid': $v.form.confirmPassword.$error }"
              id="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              size="lg"
              v-model="form.confirmPassword"
              required
            />
            <b-form-invalid-feedback v-if="!$v.form.confirmPassword.sameAsPassword">
              Passwords do not match
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            class="btn-w-120 mt-40"
            :class="{ loading }"
            :disabled="loading"
          >
            Save
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import CHANGE_PASSWORD from "@/graphql/mutations/ChangePassword.graphql";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Security",

  data() {
    return {
      loading: false,
      error: "",
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },

  methods: {
    // eslint-disable-next-line
    async submit() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return false;
      }

      try {
        this.error = "";
        this.loading = true;

        const { data } = await this.$apollo.mutate({
          mutation: CHANGE_PASSWORD,

          variables: {
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword,
          },
        });

        this.loading = false;

        if (!data.changePassword.success) {
          this.error = "Your old password seems to be wrong";
        } else {
          this.$notify({
            type: "success",
            text: "Your password is changed successfully",
          });
        }
      } catch (err) {
        this.loading = false;

        this.$notify({
          type: "error",
          text: "Oops, something went wrong",
        });
      }
    },
  },

  validations: {
    form: {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAsNewPassword: sameAs("newPassword"),
      },
    },
  },
};
</script>
