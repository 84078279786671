<template>
  <app-onboarding
    stepNumber="2"
    totalSteps="5"
    title="Social"
    desc="Please share your social accounts. Ue the URL to your profile or simply your username.
    An active account on GitHub, Stack Overflow or similar will make it easier for the employer to get to know you."
  >
    <app-user-social-links-form
      :user-links="user"
      button-label="Continue"
      @back-button-clicked="onGoBack"
      @form-submitted="onSubmit"
    />
  </app-onboarding>
</template>

<script>
import AppOnboarding from "@/components/AppOnboarding";
import AppUserSocialLinksForm from "@/components/AppUserSocialLinksForm";
import onboardingMixin from "./onboardingMixin";

export default {
  name: "SocialLinks",

  components: {
    AppOnboarding,
    AppUserSocialLinksForm,
  },

  mixins: [onboardingMixin],

  data() {
    return {
      user: {
        candidateprofile: {
        },
      },
    };
  },

  methods: {
    onGoBack() {
      this.toStep("BasicInformation");
    },

    onSubmit(data) {
      this.$emit("submit", data);

      this.toStep("Skills");
    },
  },
};
</script>
