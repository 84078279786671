import { withClientState } from "apollo-link-state";
import { merge } from "lodash";
import ui from "./resolvers/ui";
import user from "./resolvers/user";

export function createStateLink(cache) {
  return withClientState({
    cache,
    ...merge(ui, user),
  });
}
