<template>
  <auth-card>
    <h2 class="mb-20">Login</h2>
    <p class="text-muted mb-30">Enter your credentials below to login.</p>

    <b-form
      @submit.prevent="submit"
      novalidate
    >
      <b-form-group label="Email Address">
        <b-form-input
          :class="{ 'is-invalid': $v.form.username.$error }"
          id="email"
          placeholder="Enter your email"
          type="email"
          size="lg"
          v-model.trim="form.username"
          required
          v-focus
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.form.username.email">
            Enter a valid email address
          </div>
          <div v-if="!$v.form.username.required">
            Email is required
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password">
        <b-form-input
          :class="{ 'is-invalid': $v.form.password.$error }"
          id="password"
          placeholder="Enter your password"
          type="password"
          size="lg"
          v-model.trim="form.password"
          required
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.form.password.required">
            Password is required
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        class="mb-30"
        :class="{ loading }"
        type="submit"
        variant="primary"
        size="lg"
        :disabled="loading"
        block
      >
          Login
      </b-button>
    </b-form>

    <router-link
      :to="{ name: 'ForgotPassword' }"
      class="text-muted"
    >
      Forgot Password?
    </router-link>
  </auth-card>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import auth from "@/common/auth/authMixin";
import AuthCard from "./AuthCard";

export default {
  name: "Login",

  mixins: [auth],

  components: {
    AuthCard,
  },

  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },

  methods: {
    submit() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return false;
      }

      this.login(this.form);

      return true;
    },
  },

  validations: {
    form: {
      username: { email, required },
      password: { required },
    },
  },
};
</script>
