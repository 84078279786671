<template>
  <auth-card>
    <h2 class="mb-20">Forgot Password</h2>
    <p class="text-muted mb-30">Please enter your email below and we will send the instructions to you.</p>

    <b-form
      @submit.prevent="sendInstructions"
      novalidate
    >
      <b-alert
        v-if="message"
        variant="success"
        show
      >
        {{ message }}
      </b-alert>

      <b-form-group label="Email Address">
        <b-form-input
          :class="{ 'is-invalid': $v.email.$error }"
          id="email"
          placeholder="Enter your email"
          type="email"
          size="lg"
          v-model.trim="email"
          required
          v-focus
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.email.email">
            Enter a valid email address
          </div>
          <div v-if="!$v.email.required">
            Email is required
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        class="mb-30"
        :class="{ loading }"
        :disabled="loading"
        type="submit"
        variant="primary"
        size="lg"
        block
      >
          Send Instructions
      </b-button>
    </b-form>

    <router-link
      class="text-muted"
      :to="{ name: 'Login' }"
    >
      &larr; Back to Login
    </router-link>
  </auth-card>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import auth from "@/common/auth/authMixin";
import AuthCard from "./AuthCard";

export default {
  name: "ForgotPasswordForm",

  mixins: [auth],

  components: {
    AuthCard,
  },

  data() {
    return {
      email: "",
      message: "",
    };
  },

  methods: {
    async sendInstructions() {
      this.$v.email.$touch();

      if (this.$v.email.$invalid) {
        return;
      }

      this.message = await this.forgotPassword(this.email);
    },
  },

  validations: {
    email: { email, required },
  },
};
</script>
