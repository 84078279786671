<template>
  <b-card class="text-center mb-50">
    <b-row>
      <b-col lg="8" class="mx-auto">
        <h3 class="mb-30 mt-md-30">Test Unlocked!</h3>
        <p class="text-lg mb-40">To complete the logical test, click on the button below to receive an email with instructions. The link in the email will only be active for a few days. Hence, only request the test if you are ready to complete it in the near term.</p>
        <b-button
          :class="{loading}"
          :disabled="loading"
          variant="primary"
          class="btn btn-lg btn-primary mb-40"
          @click="onGenerateTest"
        >
          Receive Test
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "TestUnlocked",

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    onGenerateTest: {
      required: true
    }
  },
};
</script>
