<template>
  <div>
    <div class="d-flex align-items-center mb-30">
      <app-avatar
        class="mr-10"
        size="100px"
        :image="selectedImage"
        :firstName="firstName"
        :lastName="lastName"
      />

      <div class="user-avatar-buttons">
        <input
          ref="formFile"
          type="file"
          class="d-none"
          @change="onFileSelected"
        />

        <b-button
          variant="outline-dark"
          class="m-5"
          @click="$refs.formFile.click()"
        >
          New Image
        </b-button>

        <b-button
          variant="outline-secondary"
          class="btn-icon m-5"
          @click="onDelete"
        >
          <app-icon name="icon-trash" />
        </b-button>
      </div>


    </div>
      <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppAvatar from "@/components/AppAvatar";

export default {
  name: "UserAvatar",

  components: {
    AppAvatar,
    AppIcon,
  },

  props: {
    image: String,

    firstName: {
      type: String,
      required: true,
    },

    lastName: {
      type: String,
      required: true,
    },

    maxFileSize: {
      type: Number,
      default: 51200,
    },

    allowedFileTypes: {
      type: Array,
      default: () => ["image/jpeg"],
    },
  },

  data() {
    return {
      file: null,
      error: "",
      selectedImage: this.image,
    };
  },

  methods: {
    onFileSelected(e) {
      this.error = "";
      const imageFile = e.target.files[0];

      if (
        imageFile &&
        (imageFile.size > this.maxFileSize ||
          !this.allowedFileTypes.includes(imageFile.type))
      ) {
        this.error = "Please use only JPEG files with a maximum size of 50 KB";
        return false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.onloadend = () => {
        const imageBase64 = reader.result;

        this.selectedImage = imageBase64;
        this.$emit("user-avatar-selected", this.selectedImage);
      };

      return true;
    },

    onDelete() {
      this.selectedImage = "";
      this.$emit("user-avatar-selected", this.selectedImage);
    },
  },
};
</script>
