<template>
  <header class="skill-test-page-header d-flex align-items-center justify-content-between px-30">
    <app-logo width="100" height="36" />

    <b-button
      v-if="!questionsFinished"
      variant="link"
      class="exit-link text-body d-flex align-items-center"
      @click="onExit"
    >
      <app-icon name="icon-logout" class="mr-5" />
      <span>Exit</span>
    </b-button>
  </header>
</template>

<script>
import AppLogo from "@/components/AppLogo";
import AppIcon from "@/components/AppIcon";

export default {
  name: "SkillTestPageHeader",

  components: {
    AppLogo,
    AppIcon,
  },

  props: {
    questionsFinished: {
      required: true,
    },
  },

  methods: {
    onExit() {
      this.$emit("exit-button-clicked");
    },
  },
};
</script>

<style lang="scss">
.skill-test-page-header {
  .exit-link {
    font-size: $font-size-base;
  }
}
</style>
