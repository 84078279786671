import { setContext } from "apollo-link-context";

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});
