<template>
  <b-card class="job-description p-20" no-body>
    <!-- Job Meta -->
    <ul class="job-meta list-inline">
      <li class="list-inline-item">
        <app-icon
          name="icon-mappin" size="20px" />
        <span>{{ job.location }}</span>
      </li>

      <li class="list-inline-item">
        <app-icon name="icon-people" size="20px" />
        <span>{{ job.size }} employees</span>
      </li>

      <li class="list-inline-item">
        <app-icon name="icon-company" size="20px" />
        <span>{{ job.industry }}</span>
      </li>
    </ul>

    <!-- Description -->
    <h4 class="mb-10">Job Description</h4>
    <div  v-if="job.description">
      <div
        class="description-text"
        :class="{ expanded }"
      >
        <p class="mb-0">{{ job.description }}</p>
      </div>

      <span
        class="read-more text-muted my-10"
        @click="toggleExpanded"
      >
        {{ expanded ? "Show Less" : "Show More" }}
      </span>
    </div>

    <p class="text-muted" v-else>No description provided</p>
  </b-card>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "JobDescription",

  components: {
    AppIcon,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss">
.job-description {
  .job-meta {
    border-bottom: 1px solid $gray-200;
    padding-bottom: 1rem;

    .list-inline-item {
      display: inline-flex;
      align-items: center;
      margin-right: 1.5rem;
    }

    .icon {
      margin-right: 0.4rem;
      color: theme-color("info");
    }
  }

  .description-text {
    height: 3.125rem;
    overflow: hidden;
    margin-bottom: 1rem;

    &.expanded {
      height: auto !important;
    }
  }

  .read-more {
    cursor: pointer;
  }
}
</style>
