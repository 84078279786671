import LOGIN from "@/graphql/mutations/Login.graphql";
import REGISTER from "@/graphql/mutations/Register.graphql";
import FORGOT_PASSWORD from "@/graphql/mutations/ForgotPassword.graphql";
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import { apolloProvider } from "@/common/plugins";

import SET_AUTHENTICATION from "@/state/mutations/SetAuthentication.graphql";

export default {
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async login({ username, password }) {
      this.loading = true;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: LOGIN,

          variables: {
            username,
            password,
          },
        });

        this.loading = false;

        this.saveToken(data.tokenAuth.token);
        this.setAuthentication(true);

        // Cancel login if user is not candidate
        const user =  await this.$apollo.query({query:CURRENT_USER});
        if(!user.data.currentUser.isCandidate) {
          this.$notify({
            type: "error",
            text: "Your provided credentials is not a candidate user",
          });
          await this.logout();
        }

        if(this.$route.query.redirect) {
          await this.$router.replace(this.$route.query.redirect);
        } else {
          await this.$router.push({ name: "Profile" });
        }

      } catch (err) {
        this.loading = false;
      }
    },

    async register(uuid, user) {
      this.loading = true;

      try {
        // eslint-disable-next-line
        const { data } = await this.$apollo.mutate({
          mutation: REGISTER,

          variables: {
            uuid,
            user,
          },
        });

        this.loading = false;

        // remove registration cache
        localStorage.removeItem("user");

        this.saveToken(data.registerCandidate.token);
        this.setAuthentication(true);

        return true;
      } catch (err) {
        this.loading = false;

        return false;
      }
    },

    async logout() {
      // todo: store should be reset, if uncommented will after login throw "Store reset while query was in flight"
      const apolloClient = apolloProvider.defaultClient;
      apolloClient.stop();
      await apolloClient.cache.reset();
      await apolloClient.resetStore();
      localStorage.removeItem("accessToken");

      await this.$router.replace({ name: "Login" });
    },

    async forgotPassword(email) {
      this.loading = true;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: FORGOT_PASSWORD,

          variables: { email },
        });

        this.loading = false;

        return data.forgotPassword.message;
      } catch (err) {
        this.loading = false;
      }
    },

    saveToken(token) {
      localStorage.setItem("accessToken", `JWT ${token}`);
    },

    setAuthentication(status) {
      this.$apollo.mutate({
        mutation: SET_AUTHENTICATION,

        variables: {
          status,
        },
      });
    },
  },
};
