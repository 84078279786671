export default {
  functional: true,

  props: {
    name: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      default: "20px",
    },
  },

  render(createElement, context) {
    const { name, size } = context.props;

    try {
      // eslint-disable-next-line
      const icon = require(`./icons/${name}`).default;

      const iconStyles = {
        width: size,
        height: size,
        display: "inline-block",
      };

      return (
        <div style={iconStyles} class="skill-icon">
          <svg viewBox="0 0 24 24">
            {createElement(icon)}
          </svg>
        </div>
      );
    } catch (err) {
      return null;
    }
  },
};
