<template>
  <app-layout>
    <b-container>
      <app-page-title
        icon="icon-user"
        title="My Profile"
      />

      <div class="profile-page">
        <b-nav tabs class="nav-tabs-material mb-30">
          <b-nav-item :to="{ name: 'Profile' }" exact>
            Profile
          </b-nav-item>

          <b-nav-item :to="{ name: 'Social' }">
            Social
          </b-nav-item>

          <b-nav-item :to="{ name: 'Preferences' }">
            Preferences
          </b-nav-item>

          <b-nav-item :to="{ name: 'Security' }">
            Security
          </b-nav-item>
        </b-nav>

        <div class="mb-50">
          <transition
            enter-active-class="animated page-enter"
            leave-active-class="animated page-leave"
            mode="out-in"
          >
            <router-view />
          </transition>
        </div>
      </div>
    </b-container>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import AppPageTitle from "@/components/AppPageTitle";

export default {
  name: "ProfileIndex",

  components: {
    AppLayout,
    AppPageTitle,
  },
};
</script>
