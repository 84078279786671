<template>
  <app-icon :name="iconName" size="20px" />
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "JobListItemIcon",

  components: {
    AppIcon,
  },

  props: {
    status: {
      type: Object,
      required: true,
    },
  },

  computed: {
    iconName() {
      switch (this.status.id) {
        case 1:
          return "icon-eye";
        case 2:
          return "icon-list-ok";
        case 3:
          return "icon-talk";
        case 4:
          return "icon-hourglass-empty";
        case 5:
          return "icon-checkmark";
        case 6:
          return "icon-close";
        case 7:
          return "icon-close";
        default:
          return "icon-checkmark";
      }
    },
  },
};
</script>
