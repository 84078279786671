<template>
  <b-card
    class="job-list-item"
    :class="{unread: !job.isRead}"
    no-body
  >

    <div class="status">
      <job-status-icon
        class="mr-15"
        :status="job.status"
      />

      <div class="status-name">{{ job.status.name }}</div>
    </div>

    <span class="status-date text-muted small"></span>

    <div class="role">{{ job.role }}</div>

    <div class="location">{{ job.location }}</div>

    <div class="industry">{{ job.industry }}</div>

    <job-list-item-button
      class="mt-15 mt-md-0"
      :to="`jobs/${job.id}`"
      :actionRequired="job.actionRequired"
    />
  </b-card>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import JobStatusIcon from "../../JobStatusIcon";
import JobListItemButton from "./JobListItemButton";

export default {
  name: "JobListItem",

  components: {
    AppIcon,
    JobStatusIcon,
    JobListItemButton,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.job-list-item {
  padding: 1rem 1.25rem;
  margin-bottom: 0.875rem;
  display: grid !important;
  align-items: center;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 11rem 4.25rem 1fr 11% 12% 16%;
    grid-gap: 1rem;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 11rem 4.25rem 1fr 11% 12% 13%;
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .status {
    white-space: nowrap;
    .icon {
      color: $gray-500;
      display: none !important;

      @include media-breakpoint-up(lg) {
        display: inline !important;
      }
    }
  }

  .status-date {
    order: -1;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      order: initial;
    }
  }

  .location,
  .industry {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &.unread {
    .role,
    .status-name {
      color: $black;
      font-weight: 600;
    }
  }
}
</style>
