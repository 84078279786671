<template>
  <app-layout>
    <div v-if="job" class="page-job-detail mb-50">

      <b-container>
        <div class="d-flex justify-content-between">
          <app-page-title
            icon="icon-bag"
            :title="job.role"
          />

          <router-link class="text-dark" to="/jobs">&larr; Back to Jobs</router-link>
        </div>

        <b-row>
          <!-- Main Column -->
          <b-col lg="7">
            <job-user-actions
              v-if="job.actionRequests && job.actionRequests.length > 0"
              :actionRequests="job.actionRequests"
              :jobId="job.id"
              class="mb-30"
            />

            <job-description
              class="mb-30"
              :job="job"
            />

            <job-skills-matched :skills="job.matchedSkills" />
          </b-col>

          <!-- Activity Column -->
          <b-col lg="5">
            <job-latest-activity :activityFeed="job.statusChanges" />
          </b-col>
        </b-row>
      </b-container>

    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import AppPageTitle from "@/components/AppPageTitle";
import JobUserActions from "./JobUserActions";
import JobDescription from "./JobDescription";
import JobUserMatch from "./JobUserMatch";
import JobSkillsMatched from "./JobSkillsMatched";
import JobLatestActivity from "./JobLatestActivity";
import JOB from "@/graphql/queries/Job.graphql";

export default {
  name: "JobDetail",

  components: {
    AppLayout,
    AppPageTitle,
    JobUserActions,
    JobDescription,
    JobUserMatch,
    JobSkillsMatched,
    JobLatestActivity,
  },

  data() {
    return {
      job: {},
      routeParam: this.$route.params.id,
    };
  },

  apollo: {
    Job: {
      query: JOB,
      variables() {
        return {
          id: this.routeParam,
        };
      },

      update({ job }) {
        if (job) {
          this.job = job;
        }
      },
    },
  },
};
</script>

