<template>
  <app-layout
    :showHeader="false"
    :showSidebar="false"
  >
    <div class="skill-test-page mb-50">
      <page-header
        :questionsFinished="questionsFinished"
        @exit-button-clicked="$refs.leaveAlertModal.show()"
      />

      <b-container>
        <b-row>
          <b-col class="pt-40">

            <test-header
              v-if="!questionsFinished"
              class="mb-20"
              :skill="skill"
              :currentQuestionNumber="currentQuestionNumber"
              :timeLeft="timeLeft"
            />

            <b-progress
              class="test-progress"
              variant="info"
              height="5px"
              :value="questionsFinished ? 0 : currentQuestionNumber"
              :max="25"
            />

            <b-card
              class="question-card"
              no-body
              v-if="!questionsFinished"
            >
              <div class="question-container">
                <test-question
                  :question="question"
                  :correctChoice="correctChoice"
                  :selectedChoice="selectedChoice"
                  :result="result"
                  @select="submitAnswer"
                />

                <test-footer
                  :loading="loading"
                  :questionId="question.id"
                  :nextButtonLabel="currentQuestionNumber === 25 ? 'Finish Test' : 'Next Quesion'"
                  @next-button-clicked="nextQuestion"
                />
              </div>

              <div class="timer-container">
                <test-timer
                  ref="timer"
                  :timeLimit="question.timeLimit"
                  @stop="onTimerStop"
                  @timed-out="timedOut = true"
                />
              </div>
            </b-card>

            <test-result
              v-if="questionsFinished"
              :testName="skill.name"
              :result="questionsFinished"
            />

          </b-col>
        </b-row>
      </b-container>

      <!-- Leave Test Confirmation Modal -->
      <b-modal
        ref="leaveAlertModal"
        title="Do You Really Want to Leave the Test?"
        ok-title="Leave"
        ok-variant="danger"
        cancel-variant="outline-secondary"
        @ok="$router.replace({ name: 'SkillTests' })"
        no-fade
      >
        {{ exitModalMessage }}
      </b-modal>
    </div>
  </app-layout>
</template>

<script>
import NEXT_QUESTION from "@/graphql/mutations/NextQuestion.graphql";
import SUBMIT_ANSWER from "@/graphql/mutations/SubmitAnswer.graphql";
import AppLayout from "@/layouts/AppLayout";
import PageHeader from "./PageHeader";
import TestHeader from "./TestHeader";
import TestQuestion from "./TestQuestion";
import TestTimer from "./TestTimer";
import TestFooter from "./TestFooter";
import TestResult from "./TestResult";

export default {
  name: "SkillTest",

  components: {
    AppLayout,
    PageHeader,
    TestHeader,
    TestQuestion,
    TestTimer,
    TestFooter,
    TestResult,
  },

  props: {
    skill: {
      type: [Object, String],
    },
  },

  data() {
    return {
      currentQuestionNumber: 0,
      question: "",
      selectedChoice: "",
      correctChoice: "",
      timeTaken: null,
      loading: false,
      submitting: false,
      questionsFinished: false,
      timedOut: false,
      hasLeftPage: false,
    };
  },

  mounted() {
    document.addEventListener("visibilitychange", this.onVisibilityChange);
  },

  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },

  computed: {
    timeLeft() {
      return (
        ((process.env.VUE_APP_TOTAL_TEST_QUESTIONS -
          this.currentQuestionNumber) *
          this.question.timeLimit) /
        60
      );
    },

    result() {
      if (!this.selectedChoice || !this.correctChoice) {
        return "";
      }

      return this.selectedChoice === this.correctChoice ? "success" : "error";
    },

    exitModalMessage() {
      if (this.selectedChoice || this.timedOut) {
        return "You can finish the test later if you leave now.";
      }

      return "You haven't answered the question. It'll be counted as a wrong answer if you leave now.";
    },
  },

  beforeRouteEnter(to, from, next) {
    // If skill prop is not provided go to "/test" page.
    // Happens if page is reloaded or directly visited
    next(vm => {
      if (!vm.skill) {
        vm.$router.replace("/tests");
      }
    });
  },

  async created() {
    if (this.skill) {
      await this.nextQuestion();
    }
  },

  methods: {
    async nextQuestion() {
      this.loading = true;
      this.question = "";

      try {
        const { data } = await this.$apollo.mutate({
          mutation: NEXT_QUESTION,

          variables: { skillId: this.skill.id },
        });

        this.loading = false;
        this.hasLeftPage = false;
        this.resetChoices();
        this.question = data.nextQuestion.question;
        this.currentQuestionNumber = data.nextQuestion.question
          ? data.nextQuestion.question.questionNumber
          : 25;

        // Questions finished?
        if (
          data.nextQuestion.question === null &&
          data.nextQuestion.questionsFinished
        ) {
          this.questionsFinished = data.nextQuestion.questionsFinished;
        }
      } catch (err) {
        this.loading = false;
      }
    },

    async submitAnswer(choice) {
      // Avoid sending another request for
      // an already answered question
      if (this.selectedChoice || this.submitting) {
        return false;
      }

      // Stop the timer
      this.$refs.timer.stop();

      this.submitting = true;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: SUBMIT_ANSWER,

          variables: {
            timeTaken: this.timeTaken,
            hasLeftPage: this.hasLeftPage,
            questionId: this.question.id,
            choice: choice.character,
          },
        });

        this.submitting = false;
        this.selectedChoice = choice.character;
        this.correctChoice = data.submitAnswer.correctChoice;

        if (data.submitAnswer.status === "timedOut") {
          this.$notify({
            type: "error",
            text: "Sorry, time is out!",
          });
        }
      } catch (err) {
        this.submitting = false;
      }
    },

    resetChoices() {
      this.selectedChoice = "";
      this.correctChoice = "";
    },

    onTimerStop(counter) {
      if (this.question) {
        this.timeTaken = this.question.timeLimit - counter;
      }
    },

    onVisibilityChange() {
      if (document.visibilityState === "hidden") {
        this.hasLeftPage = true;
      }
    },
  },
};
</script>

<style lang="scss">
.test-progress {
  border-bottom-left-radius: 0;
}

.skill-test-page {
  .question-card {
    display: grid;
    grid-template-columns: 100%;

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 11rem;
    }

    .question-container {
      padding: 2rem;
    }

    .timer-container {
      padding: 1rem 2rem 0 2rem;
      border-left: 1px solid $gray-200;

      @include media-breakpoint-up(lg) {
        padding: 2rem;
      }

      @include media-breakpoint-down(md) {
        order: -1;
        border-left: none;
        min-height: 59px;
      }
    }

    .test-timer {
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: baseline;

        .counter {
          font-size: 2rem;
          margin: 0 !important;
        }

        .unit-label {
          padding-left: 0.5rem;
          margin: 0 !important;
        }

        .icon {
          order: -1;
          width: 40px;
          height: 40px;
          position: relative;
          top: 0.5rem;
        }
      }
    }
  }
}
</style>
