<template>
  <b-form
    @submit.prevent="submit"
    novalidate
  >
    <!-- Skype -->
    <b-form-group label="Skype (optional)">
      <b-form-input
        id="skype"
        placeholder="Skype"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.skypeId"
        v-focus
      />
    </b-form-group>

    <!-- Linkedin -->
    <b-form-group label="Linkedin (optional)">
      <b-form-input
        id="linkedin"
        placeholder="Linkedin"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.linkedinId"
      />
    </b-form-group>

    <!-- Stack Overflow -->
    <b-form-group label="Stack Overflow (optional)">
      <b-form-input
        id="stackoverflow"
        placeholder="Stack Overflow"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.stackoverflowId"
      />
    </b-form-group>

    <!-- GitHub -->
    <b-form-group label="GitHub (optional)">
      <b-form-input
        id="github"
        placeholder="GitHub"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.githubId"
      />
    </b-form-group>

    <!-- GitLab -->
    <b-form-group label="GitLab (optional)">
      <b-form-input
        id="gitlab"
        placeholder="GitLab"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.gitlabId"
      />
    </b-form-group>

    <!-- Bitbucket -->
    <b-form-group label="Bitbucket (optional)">
      <b-form-input
        id="bitbucket"
        placeholder="Bitbucket"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.bitbucketId"
      />
    </b-form-group>

    <!-- Blog -->
    <b-form-group label="Blog (optional)">
      <b-form-input
        id="blog"
        placeholder="Blog"
        type="text"
        :size="formFieldSize"
        v-model.trim="user.candidateprofile.blog"
      />
    </b-form-group>

    <div
      class="button-container flex-row-reverse justify-content-between mt-50"
      :class="{'d-block': !hasBackButton, 'd-flex': hasBackButton }"
    >
      <!-- Submit Button -->
      <b-button
        :class="{ 'btn-w-120': !hasBackButton, loading: $parent.loading }"
        type="submit"
        variant="primary"
        :size="formFieldSize"
        :disabled="$parent.loading"
      >
          {{ buttonLabel }}
      </b-button>

      <!-- Back Button -->
      <b-button
        v-if="hasBackButton"
        variant="link"
        class="text-muted"
        @click="onGoBack"
      >
        &larr; Back
      </b-button>
    </div>
  </b-form>
</template>

<script>
export default {
  name: "AppUserSocialLinksForm",

  props: {
    userLinks: {
      type: Object,
      required: true,
    },

    hasBackButton: {
      type: Boolean,
      default: true,
    },

    buttonLabel: {
      type: String,
      default: "Continue",
    },

    formFieldSize: {
      type: String,
      default: "lg",
    },
  },

  data() {
    return {
      user: {
        candidateprofile: {
          skypeId: "",
          linkedinId: "",
          stackoverflowId: "",
          githubId: "",
          gitlabId: "",
          bitbucketId: "",
          blog: "",
        },
      },
    };
  },

  created() {
    this.populateData();
  },

  methods: {
    populateData() {
      Object.keys(this.user.candidateprofile).forEach(key => {
        this.user.candidateprofile[key] = this.userLinks.candidateprofile[key];
      });
    },

    onGoBack() {
      this.$emit("back-button-clicked");
    },

    submit() {
      this.$emit("form-submitted", this.user);
    },
  },
};
</script>
