<template>
  <div class="selected-skill">
    <button
      type="button"
      class="close"
      aria-label="Close"
      @click="onRemove"
    >
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="skill-name text-truncate">
      {{ skill.name }}
    </div>

    <app-skill-level-select
      v-model="skill.level"
      @input="onSelect"
    />
  </div>
</template>

<script>
import AppSkillLevelSelect from "./AppSkillLevelSelect";

export default {
  name: "SelectedSkill",

  components: {
    AppSkillLevelSelect,
  },

  props: {
    skill: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onSelect(value) {
      this.$emit("select", value);
    },

    onRemove() {
      this.$emit("remove", this.skill);
    },
  },
};
</script>

<style lang="scss">
.selected-skill {
  height: 3rem;
  display: flex;
  align-items: center;
  border: 1px solid $gray-300;
  border-radius: $border-radius;
  margin-bottom: 0.625rem;
  white-space: nowrap;

  .close {
    padding-left: 0.8125rem;
    font-size: 1.375rem;
    margin-bottom: 3px;

    &:focus {
      outline: none;
    }
  }

  .skill-name {
    flex-basis: 60rem;
    height: 3rem;
    padding: 0.75rem;
    border-right: 1px solid $gray-300;
    font-size: $font-size-base;
  }

  select {
    border: 0;
  }
}
</style>

