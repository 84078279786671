<template>
  <b-modal
    ref="feedbackModal"
    class="test-feedback-modal"
    id="feedbackModal"
    title="Give Us a Feedback"
    hide-footer
    no-fade
  >
    <b-form
      @submit.prevent="submit"
      novalidate
    >
      <b-row>
        <!--Difficulty Rating -->
        <b-col>
          <b-form-group label="Difficulty">
            <b-form-select
              v-model="form.difficulty"
              :options="options"
            />
          </b-form-group>
        </b-col>

        <!--Relevancy Rating -->
        <b-col>
          <b-form-group label="Relevancy">
            <b-form-select
              v-model="form.relevancy"
              :options="options"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Message -->
      <b-form-group label="Your Message">
        <b-form-textarea
            :class="{ 'is-invalid': $v.form.message.$error }"
            placeholder="Write your feedback message here"
            :rows="3"
            :max-rows="6"
            v-model="form.message"
          />

        <b-form-invalid-feedback>
          <div v-if="!$v.form.message.required">
            Please enter your message
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Error Message -->
      <b-alert
        v-if="error"
        variant="danger"
        show
      >
        {{ error }}
      </b-alert>

      <!-- Submit Button -->
      <b-button
        type="submit"
        variant="primary"
        :class="{ loading }"
        :disabled="loading"
        block
      >
        Send
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import SUBMIT_MCQ_FEEDBACK from "@/graphql/mutations/SubmitMcqFeedback.graphql";

export default {
  name: "FeedbackModal",

  props: {
    questionId: {
      type: [String, Number],
    },
  },

  data() {
    return {
      loading: false,
      error: "",
      form: {
        difficulty: 1,
        relevancy: 1,
        message: "",
      },
      options: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
      ],
    };
  },

  methods: {
    async submit(e) {
      // Don't close the modal. We'll validate first
      e.preventDefault();

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return false;
      }

      this.loading = true;
      this.error = "";

      try {
        await this.$apollo.mutate({
          mutation: SUBMIT_MCQ_FEEDBACK,

          variables: {
            relevancy: this.form.relevancy,
            difficulty: this.form.difficulty,
            questionId: this.questionId,
            text: this.form.message,
          },
        });

        this.loading = false;
        this.$refs.feedbackModal.hide();
        this.resetForm();

        this.$notify({
          type: "success",
          text: "Thanks for your feedback",
        });
      } catch (err) {
        this.loading = false;
        this.error = "Ooops, something went wrong";
      }
    },

    resetForm() {
      // reset validation errors
      this.$v.form.$reset();

      // Reset Form values
      this.form = {
        difficulty: 1,
        relevancy: 1,
        message: "",
      };
    },
  },

  validations: {
    form: {
      message: {
        required,
      },
    },
  },
};
</script>
