<template>
  <app-layout
    :showHeader="false"
    :showFooter="false"
    :showSidebar="false"
  >
    <b-container>
      <div class="d-flex justify-content-center">
        <b-card class="card-auth">
          <app-logo class="mb-50" />

          <slot />
        </b-card>
      </div>
    </b-container>
  </app-layout>
</template>

<script>
import AppLogo from "@/components/AppLogo";
import AppLayout from "@/layouts/AppLayout";

export default {
  name: "AuthContainer",

  components: {
    AppLogo,
    AppLayout,
  },
};
</script>
