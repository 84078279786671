<template>
  <div
    class="wrapper"
    :class="wrapperClasses"
  >
    <app-error :message="appError" />

    <app-header
      v-if="showHeader"
      @sidebar-toggle-clicked="toggleSidebar"
    />

    <app-sidebar
      ref="sidebar"
      v-if="showSidebar"
    />

    <div
      class="main-content"
      :class="contentBackground"
    >
      <transition
        :enter-active-class="pageEnterClass"
        :leave-active-class="pageLeaveClass"
        mode="out-in"
        appear
      >
        <slot />
      </transition>

      <app-footer v-if="showFooter" />
      <div
        class="content-overlay"
        @click="onOverlayClick"
      />
    </div>

  </div>
</template>

<script>
import APP_ERROR from "@/state/queries/AppError.graphql";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import AppSidebar from "./AppSidebar";
import AppError from "./AppError";

export default {
  name: "AppLayout",

  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
    AppError,
  },

  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },

    showFooter: {
      type: Boolean,
      default: true,
    },

    showSidebar: {
      type: Boolean,
      default: true,
    },

    contentBackground: {
      type: String,
      default: "bg-light",
    },
  },

  data() {
    return {
      isSidebarOpen: false,
      isSidebarCollapsed: false,
    };
  },

  computed: {
    pageEnterClass() {
      return this.showHeader ? "animated page-enter" : "animated faster fadeIn";
    },

    pageLeaveClass() {
      return this.showHeader
        ? "animated page-leave"
        : "animated faster fadeOut";
    },

    wrapperClasses() {
      return {
        "no-header": !this.showHeader,
        "no-sidebar": !this.showSidebar,
        "sidebar-open": this.isSidebarOpen,
        "sidebar-collapsed": this.isSidebarCollapsed,
      };
    },
  },

  apollo: {
    appError: {
      query: APP_ERROR,
    },
  },

  created() {
    this.isSidebarCollapsed = JSON.parse(
      window.localStorage.getItem("sidebarCollapsed"),
    );
  },

  methods: {
    toggleSidebar() {
      if (window.innerWidth < 768) {
        this.isSidebarOpen = !this.isSidebarOpen;
      } else {
        this.isSidebarCollapsed = !this.isSidebarCollapsed;
        localStorage.setItem("sidebarCollapsed", this.isSidebarCollapsed);
      }
    },

    onOverlayClick() {
      this.isSidebarOpen = false;
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  display: grid;
  height: 100vh;
  grid-template-rows: $app-navbar-height 1fr;
  grid-template-areas:
    "header"
    "content";

  &.no-header,
  &.no-sidebar {
    grid-template-rows: 1fr;
    grid-template-areas: "content";
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: $app-sidebar-width 1fr;
    grid-template-areas:
      "header header"
      "sidebar content";

    &.no-header,
    &.no-sidebar {
      grid-template-columns: 1fr;
    }
  }
}

.main-content {
  position: relative;
  grid-area: content;
  padding: 2rem 5px 0;
  display: grid;
  grid-template-rows: 1fr auto;
}

.site-header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
  z-index: 1;
}
</style>
