<template>
  <svg
    viewBox="0 0 20 20"
    class="icon align-middle d-inline"
    :width="size"
    :height="size"
  >
    <use v-bind:xlink:href="href"></use>
  </svg>
</template>

<script>
export default {
  name: "AppIcon",

  props: {
    name: String,

    size: {
      type: String,
      default: "1em",
    },
  },

  computed: {
    href() {
      return `#${this.name}`;
    },
  },
};
</script>
