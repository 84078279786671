<template>
  <b-card class="text-center mb-50">
    <b-row>
      <b-col lg="8" class="mx-auto">
        <h3 class="mb-30 mt-md-30">Take The Logical Ability Test Now!</h3>
        <p class="text-lg mb-40">You can take logical ability test now! Please click the button below to start the test. Your test results will be displayed on this page when you complete the test.</p>

        <a
          class="btn btn-lg btn-primary mb-40"
          :href="test.link"
          target="_blank"
        >
          Start Now
        </a>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "TestAvailable",

  props: {
    test: {
      type: Object,
      required: true,
    },
  },
};
</script>
