<template>
  <div class="job-skills-matched">
    <h4 class="mb-20">Your Skills Matching This Job</h4>

    <app-user-skill
      v-for="skill in skills"
      :key="skill.id"
      :skill="skill"
      :options="options"
    />
  </div>
</template>

<script>
import AppUserSkill from "@/components/AppUserSkill";

export default {
  name: "JobSkillsMatched",

  components: {
    AppUserSkill,
  },

  props: {
    skills: {
      type: Array,
      required: true,
      default: () => [],
    }
  },

  data() {
    return {
      options: {
        level: false,
        status: true,
        actionButtons: false,
      }
    }
  }
};
</script>

<style lang="scss">
.job-skills-matched {
  .user-skill-item {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
}
</style>
