<template>
  <b-alert
    v-if="message"
    class="app-error mb-0 text-center"
    variant="danger"
    show
  >
   {{ message }}
  </b-alert>
</template>

<script>
export default {
  name: "AppError",

  props: {
    message: {
      type: [String, Boolean],
      required: true,
    },
  },
};
</script>
