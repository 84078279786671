<template>
  <div class="page-title d-flex align-items-center mb-30">
    <div class="title-icon d-flex align-items-center justify-content-center mr-10">
      <app-icon :name="icon" size="24px" />
    </div>
    <h1 class="h3 mb-0">{{ title }}</h1>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "AppPageTitle",

  components: {
    AppIcon,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.page-title {
  .title-icon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: darken($info-light, 5%);
    border-radius: 2.5rem;
    color: $info;
  }
}
</style>
