import { captureException, addBreadcrumb } from "@sentry/browser";
import { onError } from "apollo-link-error";
import UPDATE_APP_ERROR from "@/state/mutations/UpdateAppError.graphql";
import { apolloProvider } from "../";

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    captureException(graphQLErrors);

    addBreadcrumb({
      category: "graphQLError",
      level: "error",
      message: graphQLErrors[0].message,
    });

    apolloProvider.defaultClient.mutate({
      mutation: UPDATE_APP_ERROR,
      variables: {
        message: graphQLErrors[0].message,
      },
    });
  } else if (networkError) {
    captureException(networkError);

    addBreadcrumb({
      category: "graphQLError",
      level: "error",
      message: networkError.toString(),
    });

    apolloProvider.defaultClient.mutate({
      mutation: UPDATE_APP_ERROR,
      variables: {
        message: networkError.toString(),
      },
    });
  }
});
