<template>
  <aside class="sidebar">
    <nav class="nav sidebar-nav flex-column">
      <router-link
        class="nav-link"
        :to="{ name: 'Profile' }"

      >
        <app-icon name="icon-user" size="24px" />
        <span class="nav-link-text">Profile</span>
      </router-link>

      <router-link
        class="nav-link"
        :to="{ name: 'Skills' }"
      >
        <app-icon name="icon-bulb" size="24px" />
        <span class="nav-link-text">Skills</span>
      </router-link>

      <router-link
        class="nav-link"
        to="/tests"
      >
        <app-icon name="icon-checkmark-square" size="24px" />
        <span class="nav-link-text">Tests</span>
      </router-link>

      <router-link
        class="nav-link"
        to="/jobs"
      >
        <app-icon name="icon-bag" size="24px" />
        <span class="nav-link-text">Jobs</span>
      </router-link>
    </nav>
  </aside>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "AppSidebar",

  components: {
    AppIcon,
  },
};
</script>

<style lang="scss">
.sidebar {
  position: fixed;
  top: $app-navbar-height;
  bottom: 0;
  width: $app-sidebar-width;
  border-right: 1px solid $gray-300;
  background-color: $white;
  transform: translateX(-100%);
  transition: all 0.1s;

  @include media-breakpoint-up(md) {
    transform: translateX(0);
  }

  .sidebar-nav {
    .nav-link {
      position: relative;
      padding: 0;
      display: flex;
      height: $app-sidebar-link-height;
      align-items: center;
      font-weight: $font-weight-medium;
      color: $gray-600;
      transition: $transition-base;

      .icon {
        margin-left: 1.5rem;
        color: $gray-400;
        transition: $transition-base;
      }

      .nav-link-text {
        position: absolute;
        left: 3.125rem;
        display: inline-block;
        margin-left: 0.875rem;
        transition: opacity 0.25s;
      }

      &.router-link-active {
        color: theme-color("info");
        background-color: $app-sidebar-link-active-bg;

        .icon {
          color: $app-sidebar-link-active-color;
        }

        &:after {
          position: absolute;
          top: 0;
          right: -1px;
          display: block;
          width: 3px;
          height: 100%;
          background-color: $app-sidebar-link-active-color;
          content: "";
        }
      }

      &:hover {
        color: $app-sidebar-link-hover-color;

        .icon {
          color: $app-sidebar-link-hover-color;
        }
      }
    }
  }
}

// Sidebar Open state only in mobile

.sidebar-open {
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    .sidebar {
      transform: translateX(0);
      overflow-x: hidden;
      overflow-y: auto;
      border-right: transparent;
    }

    .content-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.35);
    }
  }
}

// Sidebar Collapsed state for views medium and larger

.sidebar-collapsed {
  @include media-breakpoint-up(md) {
    &.wrapper {
      grid-template-columns: $app-sidebar-collapsed-width 1fr;
    }

    .sidebar {
      width: $app-sidebar-collapsed-width;

      .nav-link-text {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }

    .site-header {
      grid-template-columns: $app-sidebar-collapsed-width $app-navbar-height 1fr;
    }

    .site-logo {
      .logotype {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
</style>
