<template>
  <b-card class="card user-skill-item" no-body>
    <div class="user-skill-name">
      <app-skill-icon :name="`icon-${skill.id}`" />
      <span>{{ skill.name }}</span>
    </div>

    <div v-if="options.level" class="user-skil-level">
      <app-skill-level-select
        size="sm"
        v-model="skill.level"
        @input="onLevelSelect"
      />
    </div>

    <div v-if="options.status" class="user-skill-status">
      <app-skill-validation-status
        buttonSize="sm"
        :skill="skill"
      />
    </div>

    <div v-if="options.actionButtons" class="user-skill-buttons">
      <app-icon
        name="icon-trash"
        size="24px"
        @click.native="onDeleteButtonClick(skill)"
      />
    </div>
  </b-card>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppSkillIcon from "@/components/AppSkillIcon";
import AppSkillLevelSelect from "@/components/AppSkillLevelSelect";
import AppSkillValidationStatus from "@/components/AppSkillValidationStatus";

export default {
  name: "UserSkill",

  components: {
    AppIcon,
    AppSkillIcon,
    AppSkillLevelSelect,
    AppSkillValidationStatus,
  },

  props: {
    skill: {
      type: Object,
      required: true,
    },

    options: {
      type: Object,
      default: () => ({
        level: true,
        status: true,
        actionButtons: true,
      }),
    },
  },

  methods: {
    onDeleteButtonClick(skill) {
      this.$emit("remove", skill);
    },

    onLevelSelect() {
      this.$emit("level-updated");
    },
  },
};
</script>

<style lang="scss">
.user-skill-item {
  display: grid !important;
  grid-template-columns: 8.2rem 1fr 2.5rem;
  align-items: center;
  padding: 1.25rem;
  margin-bottom: 0.875rem;

  @include media-breakpoint-up(sm) {
    height: 3.75rem;
    grid-template-columns: 1fr 9.5rem 1fr 2.5rem;
    padding: 0 1.25rem;
  }
}

.user-skill-name {
  display: flex;
  align-items: center;
  font-size: $font-size-lg;
  color: $black;

  @include media-breakpoint-down(xs) {
    margin-bottom: 0.5rem;
    grid-area: 1/1/2/4;
  }

  .skill-icon {
    margin: .125rem .75rem 0 0;
  }
}

.user-skill-status {
  justify-self: center;

  .skill-validation-status {
    .skill-validated,
    .skill-failed,
    .skill-coming-soon {
      font-size: $font-size-sm;
    }
  }
}

.user-skill-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: $gray-600;
  border-radius: $border-radius;
  cursor: pointer;
  transition: $transition-base;

  &:hover {
    color: $gray-900;
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}
</style>
