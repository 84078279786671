<template>
  <app-layout>
    <div class="page-jobs">

      <b-container>
        <app-page-title
          icon="icon-bag"
          title="My Jobs"
        />
        <job-list :jobs="jobs" />
      </b-container>

    </div>
  </app-layout>
</template>

<script>
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import AppLayout from "@/layouts/AppLayout";
import AppPageTitle from "@/components/AppPageTitle";
import JobList from "./JobList";

export default {
  name: "Jobs",

  components: {
    AppLayout,
    AppPageTitle,
    JobList,
  },

  data() {
    return {
      jobs: [],
    };
  },

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "cache-only",

      update({ currentUser }) {
        if (currentUser) {
          this.jobs = currentUser.candidateprofile.jobs;
        }
        return currentUser;
      },
    },
  },
};
</script>
