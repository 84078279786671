<template>
  <b-card
    class="action-interview-requested p-20"
    :class="{ 'border-danger': response === 'notResponded' }"
    no-body
  >
    <div class="d-flex justify-content-between">
      <!-- NO ANSWER -->
      <div
        v-if="response === 'notResponded'"
        class="text-danger"
      >
        <app-icon
          name="icon-warning"
          size="20px"
          class="mr-10"
        />
        <span>Please let us know if you’re interested in this job or not</span>
      </div>

      <!-- YES -->
      <div
        v-if="response === 'yes'"
        class="text-success"
      >
        <app-icon
          name="icon-checkmark"
          size="20px"
          class="mr-10"
        />
        <span>I'm interested in this job</span>
      </div>

      <!-- NO -->
      <div
        v-if="response === 'no'"
        class="text-danger"
      >
        <app-icon
          name="icon-close"
          size="20px"
          class="mr-10"
        />
        <span>I'm not interested in this job</span>
      </div>

      <!-- Edit Response Button -->
      <b-button
        v-if="response !== 'notResponded'"
        variant="link"
        class="text-muted p-0 h-25"
        @click="isActionButtonsVisible = !isActionButtonsVisible"
      >
        Edit
      </b-button>
    </div>

    <action-buttons
      class="mt-20"
      v-if="isActionButtonsVisible"
      :actionResponse="response"
      @change="onChange"
    />

  </b-card>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import REQUEST_RESPONSE from "@/graphql/mutations/RequestResponse.graphql";
import ActionButtons from "./ActionButtons";

export default {
  name: "ActionInterviewRequested",

  components: {
    AppIcon,
    ActionButtons,
  },

  props: {
    actionResponse: {
      type: String,
      required: true,
    },
    jobId: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      response: this.actionResponse,
      isActionButtonsVisible: this.actionResponse === "notResponded",
      loading: false
    };
  },

  methods: {
    async onChange(value) {

      try {
        this.error = "";
        this.loading = true;
        this.response = value;

        const { data } = await this.$apollo.mutate({
          mutation: REQUEST_RESPONSE,

          variables: {
            type: "interviewRequest",
            jobId: this.jobId,
            response: this.response
          },
        });

        this.loading = false;

        if (!data.requestResponse.success) {
          this.error = "Response could not be saved";
        } else {
          this.isActionButtonsVisible = false;

          this.$notify({
            type: "success",
            text: "Thank you for letting us know",
          });

          setTimeout(() => {window.location = "/jobs"}, 1000);
        }
      } catch (err) {
        this.loading = false;

        this.$notify({
          type: "error",
          text: "Oops, something went wrong",
        });
      }
    },
  },
};
</script>
