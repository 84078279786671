<template>
  <div v-if="skill" class="test-header text-muted">
    <div>{{ skill.name }} Test</div>
    <div>{{ currentQuestionNumber }} / {{ totalQuestions }} Questions, Approximate Time Left: {{ timeLeft || '' }} min</div>
  </div>
</template>

<script>
export default {
  name: "TestHeader",

  props: {
    skill: {
      required: true,
    },

    currentQuestionNumber: {
      type: Number,
      required: true,
    },

    timeLeft: {
      type: Number,
      required: true,
    },
  },

  computed: {
    totalQuestions() {
      return process.env.VUE_APP_TOTAL_TEST_QUESTIONS;
    },
  },
};
</script>

<style lang="scss">
.test-header {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
