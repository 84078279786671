<template>
  <b-form-select
    v-model="selected"
    :options="options"
    :size="size"
    @input="onInput"
  />
</template>

<script>
import ALL_CANDIDATE_TITLES from "@/graphql/queries/AllCandidateTitles.graphql";

export default {
  name: "AppCandidateTitleSelect",

  props: {
    value: String,

    size: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      selected: this.value,
      allCandidateTitles: [],
    };
  },

  computed: {
    options() {
      const titles = this.allCandidateTitles.map(title => ({
        value: title.id,
        text: title.name,
      }));

      return [{ value: null, text: "Select an Expertise" }].concat(titles);
    },
  },

  apollo: {
    allCandidateTitles: {
      query: ALL_CANDIDATE_TITLES,
    },
  },

  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>
