<template>
  <div class="datepicker">
    <b-form-group :label="label">
      <b-input-group
        :size="size"

      >
        <flat-pickr
          ref="flatpickr"
          class="form-control"
          :class="{ disabled }"
          :config="config"
          :placeholder="placeholder"
          :value="value"
          @input="onInput"
          :disabled="disabled"
        />
        <b-input-group-append v-if="canClear">
            <button
                type="button"
                class="btn btn-icon btn-outline-secondary"
                @click="clear"
            >
                <app-icon name="icon-close" />
            </button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import AppIcon from "@/components/AppIcon";

export default {
  name: "AppDatepicker",

  components: {
    AppIcon,
    flatPickr,
  },

  props: {
    label: String,

    size: String,

    placeholder: {
      type: String,
      default: "Select a date",
    },

    value: [String, Date, Array],

    canClear: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: this.value,
      config: {
        disableMobile: true,
      },
    };
  },

  methods: {
    clear() {
      this.selected = null;
    },

    open() {
      this.$refs.flatpickr.fp.open();
    },

    onInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  .btn {
    border-color: $gray-300;

    &:hover {
      color: $gray-700;
      background-color: $gray-100;
    }

    .icon {
      font-size: 1rem;
    }
  }
}
</style>
