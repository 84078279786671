<template>
  <div class="job-status-activity-item d-flex align-items-center">
    <div class="job-status-icon mr-15"><job-status-icon :status="activity" /></div>
    <div class="text-dark mr-5">{{ activity.name }}</div>
  </div>
</template>

<script>
import JobStatusIcon from "../../JobStatusIcon";

export default {
  name: "JobLatestActivityItem",

  components: {
    JobStatusIcon,
  },

  props: {
    activity: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.job-status-activity-item {
  .job-status-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3rem;
    background-color: $gray-100;
    color: $gray-600;
    z-index: 1;
  }
}
</style>
