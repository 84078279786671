<template>
  <div class="app-spinner" v-show="status" :style="spinnerStyle"></div>
</template>

<script>
// Slightly modified version of https://github.com/sergeyloysha/vue-spinner-component/blob/master/src/Spinner.vue

export default {
  props: {
    status: {
      type: Boolean,
      default: true,
    },

    rotation: {
      type: Boolean,
      default: true,
    },

    size: {
      type: Number,
      default: 40,
    },

    depth: {
      type: Number,
      default: 4,
    },

    speed: {
      type: Number,
      default: 0.75,
    },

    color: {
      type: String,
      default: "#1e92a2",
    },
  },

  data() {
    return {
      rotationAnimations: ["forward", "backward"],
      sizeUnits: "px",
      timeUnits: "s",
    };
  },

  computed: {
    rotationDirection() {
      return this.rotation
        ? this.rotationAnimations[0]
        : this.rotationAnimations[1];
    },

    spinnerSize() {
      return this.size + this.sizeUnits;
    },

    spinnerDepth() {
      return this.depth + this.sizeUnits;
    },

    spinnerSpeed() {
      return this.speed + this.timeUnits;
    },

    spinnerStyle() {
      return {
        borderTopColor: this.hexToRGB(this.color, 0.15),
        borderRightColor: this.hexToRGB(this.color, 0.15),
        borderBottomColor: this.hexToRGB(this.color, 0.15),
        borderLeftColor: this.color,
        width: this.spinnerSize,
        height: this.spinnerSize,
        borderWidth: this.spinnerDepth,
        animationName: this.rotationDirection,
        animationDuration: this.spinnerSpeed,
      };
    },
  },

  methods: {
    hexToRGB(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }

      return `rgb(${r}, ${g}, ${b})`;
    },
  },
};
</script>

<style lang="scss">
.app-spinner {
  border-style: solid;
  transform: translateZ(0);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

@keyframes forward {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes backward {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
