<template>
  <div
    v-if="!logicalTest.status"
    class="d-flex justify-content-center my-40"
  >
    <app-spinner />
  </div>
  <div
    v-else
    class="page-logical-ability-tests"
  >
    <component
      :is="currentComponent"
      :test="logicalTest"
      :onGenerateTest="onGenerateTest"
      :onUnlock="onUnlock"
      :user="user"
      :loading="loading"
      :locked="locked"
    />
  </div>
</template>

<script>
import AppSpinner from "@/components/AppSpinner";
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import REQUEST_TEST from "@/graphql/mutations/RequestTest.graphql";
import TestNotAvailable from "./TestNotAvailable";
import TestAvailable from "./TestAvailable";
import TestCompleted from "./TestCompleted";

export default {
  name: "LogicalAbilityTests",

  components: {
    AppSpinner,
    TestNotAvailable,
    TestAvailable,
    TestCompleted,
  },

  data() {
    return {
      currentComponent: null,
      loading: false,
      locked: true,
      logicalTest: {
        status: null,
        link: null,
        overall: null,
        detail: null,
      },
    };
  },

  computed: {
    user() {
      return {
        fullName: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
        firstName: this.currentUser.firstName,
      };
    },
  },

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      update({ currentUser }) {
        if (currentUser) {
          const { logicalTest } = currentUser.candidateprofile;

          if (logicalTest.status === "notAvailable") {
            this.logicalTest.status = logicalTest.status;
            this.currentComponent = "TestNotAvailable";

            return currentUser;
          } else if (logicalTest.status === "TestAvailable") {
            this.logicalTest.status = logicalTest.status;
            this.logicalTest.link = logicalTest.link;
            this.currentComponent = "TestAvailable";

            return currentUser;
          } else if (logicalTest.status === "completed") {
            this.logicalTest = {
              status: logicalTest.status,
              link: logicalTest.link,
              overall: {
                name: "Overall Score",
                score: logicalTest.resultOverall,
                text: logicalTest.resultOverallText,
              },
              detail: [
                {
                  name: "Inductive Reasoning",
                  score: logicalTest.resultInductive,
                  text: logicalTest.resultInductiveText,
                  variant: "primary",
                },
                {
                  name: "Deductive Reasoning",
                  score: logicalTest.resultDeductive,
                  text: logicalTest.resultDeductiveText,
                  variant: "warning",
                },
                {
                  name: "Numerical Reasoning",
                  score: logicalTest.resultNumerical,
                  text: logicalTest.resultNumericalText,
                  variant: "danger",
                },
              ],
            };

            this.currentComponent = "TestCompleted";

            return currentUser;
          }
        }
      },
    },
  },

  methods: {
    async onGenerateTest() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: REQUEST_TEST,
        });
        await this.$apollo.queries.currentUser.refetch();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: "Oops, something went wrong",
        });
      }
    },
    async onUnlock() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.locked = false;
      }, 2000);
    }
  },
};
</script>

<style lang="scss">
.test-locked-text {
  font-size: 18px;
  font-weight: $font-weight-light;

  @include media-breakpoint-up(md) {
    padding-left: 20%;
    padding-right: 20%;
  }
}
</style>
