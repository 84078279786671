<template>
  <div class="job-user-actions">
    <div
      v-for="action in actionRequests"
      :key="action.type"
    >
      <component
        :is="currentComponent(action.type)"
        :actionResponse="action.response"
        :jobId="jobId"
      />
    </div>
  </div>
</template>

<script>
import ActionInterviewRequested from "./ActionInterviewRequested";

export default {
  name: "JobUserActions",

  components: {
    ActionInterviewRequested,
  },

  props: {
    actionRequests: {
      type: Array,
    },
    jobId: {
      type: Number,
      require: true,
    }
  },

  methods: {
    currentComponent(actionType) {
      if (actionType === "interviewRequested") {
        return "ActionInterviewRequested";
      }
    },
  },
};
</script>
