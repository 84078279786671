import Vue from "vue";

const focus = {
  inserted(el) {
    el.focus();
  },
};

export default function registerDirectives() {
  Vue.directive("focus", focus);
}
