<template>
  <div
    v-if="timeLimit"
    class="test-timer text-center"
    :class="timerClass"
  >
    <div class="counter mb-5">{{ counter }}</div>
    <div class="unit-label small mb-5">seconds</div>
    <app-icon name="icon-stopwatch" size="60px" />
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "TestTimer",

  components: {
    AppIcon,
  },

  props: {
    timeLimit: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      counter: 0,
      interval: 0,
      startTime: 0
    };
  },

  computed: {
    timerClass() {
      return {
        warning: this.counter < 10,
        danger: this.counter === 0,
      };
    },
  },

  watch: {
    timeLimit() {
      clearInterval(this.interval);

      if (this.timeLimit) {
        this.counter = this.timeLimit;
        this.start();
      }
    },
  },

  beforeDestroy() {
    this.stop();
  },

  methods: {
    countDown() {
      if (this.counter > 0) {   
        this.counter = parseInt(Math.ceil(this.timeLimit - (Date.now() - this.startTime) / 1000));
      } else {
        this.counter = 0;
        this.startTime = 0;
        this.stop();
        this.$emit("timed-out");
      }
    },

    start() {
      this.startTime = Date.now();
      this.interval = setInterval(this.countDown, 1000);
    },

    stop() {
      clearInterval(this.interval);
      this.$emit("stop", this.counter);
    },
  },
};
</script>

<style lang="scss">
.test-timer {
  .counter {
    font-size: 3.25rem;
    line-height: 1;
    color: theme-color("info");
  }

  .unit-label {
    letter-spacing: 1px;
    line-height: 1;
    color: $text-muted;
  }

  .icon {
    color: theme-color("info");
    opacity: 0.3;
  }

  &.warning {
    .counter,
    .unit-label,
    .icon {
      color: theme-color("warning");
    }
  }

  &.danger {
    .counter,
    .unit-label,
    .icon {
      color: theme-color("danger");
    }
  }
}
</style>
