export default {
  defaults: {
    isAuthenticated: false,
  },

  resolvers: {
    Mutation: {
      setAuthentication(_, { status }, { cache }) {
        cache.writeData({ data: { isAuthenticated: status } });

        return null;
      },
    },
  },
};
