<template>
  <b-card class="user-social-links">
    <div
      v-if="!currentUser"
      class="d-flex justify-content-center my-40"
    >
      <app-spinner />
    </div>

    <div v-else>
      <b-row>
        <b-col lg="5">
          <app-user-social-links-form
            :user-links="currentUser"
            :has-back-button="false"
            button-label="Save"
            button-size=""
            formFieldSize=""
            @form-submitted="save"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import user from "@/common/mixins/user";
import AppSpinner from "@/components/AppSpinner";
import AppUserSocialLinksForm from "@/components/AppUserSocialLinksForm";

export default {
  name: "Social",

  components: {
    AppSpinner,
    AppUserSocialLinksForm,
  },

  mixins: [user],

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "cache-only",
    },
  },

  methods: {
    save(userData) {
      this.updateUser(userData);
    },
  },
};
</script>
