import ProfileIndex from "@/views/Profile/index";
import Profile from "@/views/Profile/Profile";
import Social from "@/views/Profile/Social";
import Preferences from "@/views/Profile/Preferences";
import Security from "@/views/Profile/Security";
import Skills from "@/views/Skills";
import TestsIndex from "@/views/Tests";
import SkillTests from "@/views/Tests/SkillTests";
import SkillTest from "@/views/Tests/SkillTests/SkillTest";
import SkillTestIntro from "@/views/Tests/SkillTests/SkillTestIntro";
import LogicalAbilityTests from "@/views/Tests/LogicalAbilityTests";
import Jobs from "@/views/Jobs";
import JobDetail from "@/views/Jobs/JobDetail";

import Components from "@/views/Components";

export default [
  {
    path: "/",
    redirect: "/profile",
  },
  {
    path: "/profile",
    component: ProfileIndex,
    children: [
      {
        path: "",
        name: "Profile",
        component: Profile,
      },
      {
        path: "social",
        name: "Social",
        component: Social,
      },
      {
        path: "preferences",
        name: "Preferences",
        component: Preferences,
      },
      {
        path: "security",
        name: "Security",
        component: Security,
      },
    ],
  },
  {
    path: "/skills",
    name: "Skills",
    component: Skills,
  },
  {
    path: "/tests",
    component: TestsIndex,
    children: [
      {
        path: "",
        name: "SkillTests",
        component: SkillTests,
      },
      {
        path: "logical-ability",
        name: "LogicalAbilityTests",
        component: LogicalAbilityTests,
      },
    ],
  },
  {
    path: "/tests/skills/:skillId",
    name: "SkillTest",
    component: SkillTest,
    props: true,
  },
  {
    path: "/tests/skills/:skillId/intro",
    name: "SkillTestIntro",
    component: SkillTestIntro,
    props: true,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/jobs/:id",
    name: "JobDetail",
    component: JobDetail,
  },
  {
    path: "/components",
    name: "Components",
    component: Components,
  },
];
