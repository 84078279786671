<template>
  <b-card class="text-center mb-50">
    <div class="icon-circle-bg warning mt-50 mb-40 mx-auto">
      <app-icon name="icon-padlock" size="60px" />
    </div>
    <b-row>
      <b-col lg="8" class="mx-auto">
        <p class="text-lg mb-40">Completing a logical test could improve your chances of being approached by exciting companies in Europe and the US. Please understand that a logical test takes about 45 minutes to complete and should be done in a concentrated setting.</p>
        <b-button
          :class="{loading}"
          :disabled="loading"
          variant="primary"
          class="btn btn-lg btn-primary mb-40"
          @click="onUnlock"
        >
          Unlock logical ability test
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "TestLocked",

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    onUnlock: {
      required: true
    }
  },

  components: {
    AppIcon,
  },
};
</script>
