<template>
  <app-onboarding
    stepNumber="1"
    totalSteps="5"
    title="Basic Information"
    desc="We would like to know you better.
    Please provide us with your personal information."
  >
    <app-user-profile-form
      :user-profile="user"
      @back-button-clicked="onGoBack"
      @form-submitted="onSubmit"
    />
  </app-onboarding>
</template>

<script>
import AppUserProfileForm from "@/components/AppUserProfileForm";
import AppOnboarding from "@/components/AppOnboarding";
import onboardingMixin from "./onboardingMixin";

export default {
  name: "BasicInformation",

  components: {
    AppUserProfileForm,
    AppOnboarding,
  },

  mixins: [onboardingMixin],

  data() {
    return {
      user: {
        candidateprofile: {
          title: null,
          nationality: null,
          location: null,
        },
      },
      emailRegistered: false,
      checkingEmail: false,
      emailError: "",
      success: false,
    };
  },

  methods: {
    onGoBack() {
      this.toStep("Introduction");
    },

    onSubmit(data) {
      this.$emit("submit", data);

      this.toStep("SocialLinks");
    },
  },
};
</script>
