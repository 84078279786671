<template>
  <b-card>
    <b-form
      v-if="user"
      @submit.prevent="submit"
      novalidate
    >
      <h5 class="mb-20">Availability</h5>
      <div class="d-flex align-items-center">
        <b-form-checkbox
          v-model="user.candidateprofile.status.id"
          :value="3"
          :unchecked-value="2"
        >
          On hold until
        </b-form-checkbox>

        <app-datepicker
          ref="datepicker"
          class="ml-10"
          :disabled="user.candidateprofile.status.id !== 3"
          :value="user.candidateprofile.status.date"
          @input="onInput"
        />
      </div>

      <h5 class="mt-20 mb-20">Job Preferences</h5>
      <p class="text-muted">In this section you let us and employers know what kind of job offer you would consider.</p>
      <div
        v-for="job_preference in user.candidateprofile.jobPreferences"
        :key="'job_preference' + job_preference.id"
      >
        <b-form-checkbox
          v-model="job_preference.selected"
        >
          {{ job_preference.name}} <span class="text-muted">{{ job_preference.longName}}</span>
        </b-form-checkbox>
      </div>

      <h5 class="mt-40 mb-20">Excluded Industries</h5>

      <p class="text-muted">Please select below if you are not willing to work in any specific industries.</p>

      <div
        v-for="industry in user.candidateprofile.industryPreferences"
        :key="'industry' + industry.id"
      >
        <b-form-checkbox
          v-model="industry.excluded"
        >
          {{ industry.name}}
        </b-form-checkbox>

        <b-form-input
          v-if="industry.name === 'Other'"
          class="other-industries"
          type="text"
          placeholder="Please Specify"
          :disabled="!industry.excluded"
          v-model="industry.note"
        />
      </div>

      <b-button
        class="btn-w-120 mt-40"
        type="submit"
        variant="primary"
      >
        Save
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import AppDatepicker from "@/components/AppDatepicker";
import user from "@/common/mixins/user";

export default {
  name: "Preferences",

  components: {
    AppDatepicker,
  },

  mixins: [user],

  data() {
    return {
      user: {
        candidateprofile: {
          status: {
            date: null,
            id: 3,
          },
          jobPreferences: [],
          industryPreferences: [],
        },
      },
    };
  },

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "cache-only",

      update({ currentUser }) {
        if (currentUser) {
          this.user.candidateprofile.jobPreferences = currentUser.candidateprofile.jobPreferences.map(
            jobPreference => ({
              id: jobPreference.id,
              name: jobPreference.name,
              longName: jobPreference.longName,
              description: jobPreference.description,
              selected: jobPreference.selected,
            }),
          );

          this.user.candidateprofile.industryPreferences = currentUser.candidateprofile.industryPreferences.map(
            industry => ({
              id: industry.id,
              name: industry.name,
              excluded: industry.excluded,
              note: industry.note,
            }),
          );

          this.user.candidateprofile.status = {
            id: currentUser.candidateprofile.status.id,
            date: currentUser.candidateprofile.status.date,
          };
        }
      },
    },
  },

  watch: {
    // eslint-disable-next-line
    "user.candidateprofile.status.id": function(value) {
      if (value === 3) {
        this.$nextTick(() => {
          this.$refs.datepicker.open();
        });
      } else if (value === 2) {
        this.user.candidateprofile.status.date = null;
      }
    },
  },

  methods: {
    submit() {
      this.updateUser(this.user);
    },

    onInput(value) {
      this.user.candidateprofile.status.date = value || null;
    },
  },
};
</script>

<style lang="scss">
.other-industries {
  display: inline-block;
  width: 15rem;
}
</style>
