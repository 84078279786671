<template>
  <div class="test-footer d-flex align-items-center mt-50">
    <div class="small pr-30">
      <span class="text-muted">Something wrong with this question?</span>
      <b-btn
        variant="link"
        class="text-body d-block p-0"
        v-b-modal.feedbackModal
      >
        Give us a feedback!
      </b-btn>
    </div>

    <b-button
      class="btn-w-140 ml-auto"
      :class="{ loading }"
      :disabled="loading"
      variant="primary"
      size="lg"
      @click="$emit('next-button-clicked')"
    >
      {{ nextButtonLabel }}
    </b-button>

    <feedback-modal
      ref="feedbackModal"
      :questionId="questionId"
    />
  </div>
</template>

<script>
import FeedbackModal from "./FeedbackModal";

export default {
  name: "TestFooter",

  components: {
    FeedbackModal,
  },

  props: {
    questionId: {
      type: [String, Number],
    },

    loading: {
      type: Boolean,
      required: true,
    },

    nextButtonLabel: {
      type: String,
      required: true,
      default: "Next Question",
    },
  },
};
</script>

<style lang="scss">
.test-feedback-modal {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 22.5rem;
    }
  }
}
</style>
