<template>
  <app-layout>
    <div v-if="skill" class="mb-50">
       <b-container>
          <div class="page-title mb-30">
            <!-- Todo: Add Library Icon Here -->
            <h1 class="h3 mb-0">{{ skill.name }}</h1>
          </div>

          <b-row>
            <b-col>
              <b-card class="test-into text-center">
                <!-- Icon -->
                <div class="icon-circle-bg info my-40 mx-auto">
                  <app-icon name="icon-stopwatch" size="60px" />
                </div>

                <b-row>
                  <b-col lg="8" class="mx-auto">
                    <p class="text-lg mb-40">We will ask you <strong>25 questions</strong> in total. Each question needs
                    to be answered within the time period specified according to the difficulty of
                    the question. Total time for this test is approximately <strong>10 minutes</strong>.
                    Please click the Start button below when you’re ready.</p>

                    <b-button
                      variant="outline-secondary"
                      class="btn-w-140 mb-40 mr-10"
                      size="lg"
                      :to="{ name: 'SkillTests' }"
                      exact
                    >
                      Not Now
                    </b-button>

                    <b-button
                      variant="primary"
                      class="btn-w-140 mb-40"
                      size="lg"
                      :to="{ name: 'SkillTest', params: { skillId: skill.id, skill } }"
                      exact
                    >
                      Start Test
                    </b-button>
                  </b-col>
                </b-row>

              </b-card>
            </b-col>
          </b-row>
       </b-container>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import AppIcon from "@/components/AppIcon";

export default {
  name: "SkillTestIntro",

  components: {
    AppLayout,
    AppIcon,
  },

  props: {
    skill: {
      type: Object,
    },
  },

  beforeRouteEnter(to, from, next) {
    // If skill prop is not provided go to "/test" page.
    // Happens if page is reloaded or directly visited
    // or user hits back button after test starts
    next(vm => {
      if (!vm.skill) {
        vm.$router.replace("/tests");
      }
    });
  },
};
</script>
