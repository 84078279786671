<template>
  <app-layout>
    <div>
      <div
        v-if="!currentUser || !allSkills"
        class="d-flex justify-content-center pt-50"
      >
        <app-spinner />
      </div>

      <template v-else>
        <b-container>
          <app-page-title
            icon="icon-bulb"
            title="My Skills"
          />

          <b-row>
            <b-col sm="7" lg="5" class="mb-20">
              <app-skill-select
                :skills="skills"
                @select="addSkill"
              />
            </b-col>
            <b-col sm="5" lg="7">
              <b-button
                variant="primary"
                class="mb-30"
                @click="$bvModal.show('allSkillsModal')"
              >
                Show All Skills
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="mb-50">
              <div class="user-skill-list-titles user-skill-item text-muted">
                <div>Skill</div>
                <div>Level</div>
                <div class="text-center">Test Status</div>
              </div>

              <app-user-skill
                v-for="skill in user.candidateprofile.skills"
                :key="skill.id"
                :skill="skill"
                @level-updated="onSkillLevelUpdate"
                @remove="$refs.deleteSkillModal.show(skill)"
              />
            </b-col>
          </b-row>
        </b-container>

        <b-modal
          id="allSkillsModal"
          modal-class="modal-full-screen all-skills-modal"
          hide-header
          hide-footer
          hide-header-close
          no-fade
        >
          <app-skill-select-all
            :skills="skills"
            :userSkills="user.candidateprofile.skills"
            @skills-added="addSkills"
          />
        </b-modal>
      </template>

      <delete-skill-modal
        ref="deleteSkillModal"
        @ok="removeSkill"
      />
    </div>
  </app-layout>
</template>

<script>
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import ALL_SKILLS from "@/graphql/queries/AllSkills.graphql";

import { findIndex, sortBy } from "lodash";
import AppLayout from "@/layouts/AppLayout";
import AppSpinner from "@/components/AppSpinner";
import AppPageTitle from "@/components/AppPageTitle";
import AppSkillSelect from "@/components/AppSkillSelect";
import AppSkillSelectAll from "@/components/AppSkillSelectAll";
import AppUserSkill from "@/components/AppUserSkill";
import userMixin from "@/common/mixins/user";
import DeleteSkillModal from "./DeleteSkillModal";

export default {
  name: "Skills",

  components: {
    AppLayout,
    AppSpinner,
    AppPageTitle,
    AppSkillSelect,
    AppUserSkill,
    AppSkillSelectAll,
    DeleteSkillModal,
  },

  mixins: [userMixin],

  data() {
    return {
      allSkills: [],
      user: {
        candidateprofile: {
          skills: [],
        },
      },
    };
  },

  computed: {
    // User's selected skills are filtered out in this list
    skills() {
      const selectedSkillIds = this.user.candidateprofile.skills.map(skill =>
        parseInt(skill.id, 10),
      );

      const options = this.allSkills.filter(
        skill => !selectedSkillIds.includes(parseInt(skill.id, 10)),
      );

      return sortBy(options, option => option.name.toLowerCase());
    },
  },

  apollo: {
    allSkills: {
      query: ALL_SKILLS,
    },

    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "cache-only",

      update({ currentUser }) {
        if (currentUser) {
          this.user.candidateprofile.skills = currentUser.candidateprofile.skills.map(
            skill => ({
              id: skill.id,
              level: skill.level,
              name: skill.name,
              validationStatus: skill.validationStatus,
            }),
          );
        }

        return currentUser;
      },
    },
  },

  methods: {
    addSkill(skill) {
      const newSkill = {
        id: parseInt(skill.id, 10),
        name: skill.name,
        level: 1,
      };

      this.user.candidateprofile.skills.unshift(newSkill);
      this.user.candidateprofile.skills = this.skillData(this.user);
      this.updateUser(this.user);
    },

    addSkills(skills) {
      const newSkills = skills.map(skill => ({
        id: parseInt(skill.id, 10),
        name: skill.name,
        level: 1,
      }));

      this.user.candidateprofile.skills = [
        ...newSkills,
        ...this.user.candidateprofile.skills,
      ];

      this.user.candidateprofile.skills = this.skillData(this.user);
      this.updateUser(this.user);
    },

    removeSkill(skill) {
      const index = findIndex(this.user.candidateprofile.skills, {
        id: skill.id,
      });

      this.user.candidateprofile.skills.splice(index, 1);
      this.user.candidateprofile.skills = this.skillData(this.user);
      this.updateUser(this.user);
    },

    onSkillLevelUpdate() {
      this.user.candidateprofile.skills = this.skillData(this.user);
      this.updateUser(this.user);
    },

    // Remove validationStatus field from user skill data
    // since we can't really update that field
    skillData(user) {
      return user.candidateprofile.skills.map(skill => ({
        id: skill.id,
        level: skill.level,
        name: skill.name,
      }));
    },
  },
};
</script>

<style lang="scss">
.user-skill-list-titles {
  font-size: $font-size-lg;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
</style>
