<template>
  <header class="site-header">
    <div class="site-logo">
      <router-link to="/">
        <app-logo width="100" height="36" />
      </router-link>
    </div>

    <div
      class="sidebar-toggle"
      @click="toggleSidebar"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="13"><path fill="currentColor" d="M.5 1h20c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H.5C.2 0 0 .2 0 .5s.2.5.5.5zM20.5 6H.5c-.3 0-.5.2-.5.5s.2.5.5.5h20c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM10.5 12H.5c-.3 0-.5.2-.5.5s.2.5.5.5h10c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/></svg>
    </div>

    <div v-if="currentUser" class="ml-auto">
      <user-menu :user="currentUser" />
    </div>
  </header>
</template>

<script>
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import AppLogo from "@/components/AppLogo";
import auth from "@/common/auth/authMixin";
import UserMenu from "./UserMenu";

export default {
  name: "AppHeader",

  components: {
    AppLogo,
    UserMenu,
  },

  mixins: [auth],

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "cache-only",
    },
  },

  methods: {
    toggleSidebar() {
      this.$emit("sidebar-toggle-clicked");
    },
  },
};
</script>

<style lang="scss">
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $app-navbar-height;
  display: grid;
  grid-template-columns: $app-navbar-height 1fr;
  align-items: center;
  border-bottom: 1px solid $gray-300;
  background-color: $white;
  z-index: 1;

  @include media-breakpoint-up(md) {
    padding-right: 0.5rem;
    grid-template-columns: $app-sidebar-width $app-navbar-height 1fr;
  }

  .site-logo {
    margin: 0;
    position: absolute;
    top: 0.75rem;
    left: calc(50% - 3.125rem);

    @include media-breakpoint-up(md) {
      margin-left: 0.8125rem;
      position: initial;
      border-right: 1px solid $gray-300;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .sidebar-toggle {
    justify-self: center;
    color: $gray-800;
    cursor: pointer;
    transition: $transition-base;

    &:hover {
      padding: 0.625rem;
      border-radius: $border-radius;

      @include media-breakpoint-up(md) {
        background-color: $gray-100;
      }
    }

    &:active {
      background-color: $gray-200;
    }
  }

  // Navigation Links (Drobdown Buttons)

  .btn-link {
    height: $app-navbar-height;
    text-transform: initial;
    letter-spacing: initial;
  }

  // Dropdowns

  .dropdown-menu {
    margin-top: -1px;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
