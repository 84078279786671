<template>
  <b-card class="test-result text-center">
    <h3 class="mt-30 mb-40">Your {{ testName }} Score</h3>

    <skill-score :score="result.score" />

    <b-row>
      <b-col lg="8" class="mx-auto">
        <p class="text-lg mb-40">{{ result.text }}</p>
      </b-col>
    </b-row>

    <b-button
      class="btn-w-140 ml-auto mb-40"
      variant="primary"
      size="lg"
      :to="{ name: 'SkillTests' }"
      exact
    >
      Back to Tests
    </b-button>
  </b-card>
</template>

<script>
import SkillScore from "../SkillCard/SkillScore";

export default {
  name: "TestResult",

  components: {
    SkillScore,
  },

  props: {
    testName: {
      type: String,
      required: true,
    },

    result: {
      type: Object,
      required: true,
    },
  },
};
</script>
