import UPDATE_USER from "@/graphql/mutations/UpdateUser.graphql";

export default {
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async updateUser(user) {
      try {
        this.loading = true;

        await this.$apollo.mutate({
          mutation: UPDATE_USER,

          variables: { user },
        });

        this.loading = false;
        this.$notify({
          type: "success",
          text: "Saved Successfully",
        });
        return user;
      } catch (err) {
        this.loading = false;

        this.$notify({
          type: "error",
          text: "Oops, something went wrong",
        });
      }
    },
  },
};
