<template>
  <b-card
    class="skill-test-item text-center"
    no-body
  >
    <app-skill-icon :name="`icon-${skill.id}`" />

    <div class="skill-test-title text-truncate px-30 mb-30">{{ skill.name }}</div>

    <div class="text-muted mb-20">Your Score</div>

    <skill-score :score="skill.score" />

    <app-skill-validation-status
      :skill="skill"
      block
    />
  </b-card>
</template>

<script>
import AppSkillValidationStatus from "@/components/AppSkillValidationStatus";
import AppSkillIcon from "@/components/AppSkillIcon";
import SkillScore from "./SkillScore";

export default {
  name: "SkillTestCard",

  components: {
    AppSkillIcon,
    SkillScore,
    AppSkillValidationStatus,
  },

  props: {
    skill: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.skill-test-item {
  padding: 1.5rem 1.25rem 1.25rem;
  position: relative;

  .skill-icon {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
  }

  .skill-test-title {
    font-size: 1.125rem;
    line-height: 1;
    color: $black;
  }
}
</style>
