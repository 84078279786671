<template>
  <app-onboarding :showSidebar="false">
    <div class="onboarding-introduction text-center">
      <app-logo class="mx-auto mt-50" />
      <hr class="m-50" />
      <h3 class="mb-40">Welcome to 10Xrecruit!</h3>
      <p class="text-muted mb-50">
        The first step as a candidate with 10Xrecruit is to complete your profile.
        This will only take a few minutes and the more information you can give us,
        the better we can match you with potential job offers.
      </p>
      <b-button
        variant="primary"
        size="lg"
        @click="toStep('BasicInformation')"
      >
        Start Now!
      </b-button>
    </div>
  </app-onboarding>
</template>

<script>
import AppOnboarding from "@/components/AppOnboarding";
import AppLogo from "@/components/AppLogo";
import onboardingMixin from "./onboardingMixin";

export default {
  name: "Introduction",

  components: {
    AppOnboarding,
    AppLogo,
  },

  mixins: [onboardingMixin],
};
</script>

<style lang="scss">
.onboarding-introduction {
  @include media-breakpoint-up(lg) {
    padding-left: 15%;
    padding-right: 15%;
  }
}
</style>
