<template>
  <multiselect
    class="select-skill"
    :class="size"
    :options="skills"
    label="name"
    track-by="name"
    placeholder="Click to search and add a skill"
    :showLabels="false"
    :close-on-select="closeOnSelect"
    @select="onSelect">
    <template slot="option" slot-scope="skill">
      {{ skill.option.name }} <b-badge v-if="currentYear === new Date(skill.option.createdAt).getFullYear()" variant="info">NEW</b-badge>
    </template>
  </multiselect>
</template>

<script>
// import { sortBy } from "lodash";
import Multiselect from "vue-multiselect";

export default {
  name: "AppSkillSelect",

  components: {
    Multiselect,
  },

  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },

  props: {
    skills: {
      type: Array,
      required: true,
    },

    size: {
      type: String,
      default: "",
    },

    closeOnSelect: {
      type: Boolean,
      default: false,
    },

    value: String,
  },

  methods: {
    onSelect(skill) {
      this.$emit("select", skill);
    },
  },
};
</script>

<style lang="scss">
.select-skill {
  font-size: $font-size-base !important;

  .multiselect__single {
    color: $gray-500;
    margin-bottom: 0;
  }

  &.lg {
    .multiselect__select {
      width: 3rem;
      height: 3rem;
    }

    .multiselect__tags {
      height: 3rem;
      padding: 0.6875rem calc(2.5rem + 2px) 0 0.75rem;
    }
  }
}
</style>
