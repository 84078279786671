export default {
  defaults: {
    appError: false,
    onboardingStep: "Introduction",
  },

  resolvers: {
    Mutation: {
      updateAppError: (_, { message }, { cache }) => {
        cache.writeData({ data: { appError: message } });

        return null;
      },

      setOnboardingStep: (_, { stepName }, { cache }) => {
        cache.writeData({ data: { onboardingStep: stepName } });

        return null;
      },
    },
  },
};
