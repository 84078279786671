<template>
  <div class="job-list mb-50">
    <div class="job-list-titles text-muted">
      <div>Event</div>
      <div></div>
      <div>Role</div>
      <div>Location</div>
      <div>Industry</div>
      <div>Action</div>
    </div>

    <job-list-item
      v-for="job in jobs"
      :key="job.id"
      :job="job"
    />
  </div>
</template>

<script>
import JobListItem from "./JobListItem";

export default {
  name: "JobList",

  props: {
    jobs: {
      type: Array,
      required: true,
    },
  },

  components: {
    JobListItem,
  },
};
</script>

<style lang="scss">
.job-list-titles {
  padding: 1rem 1.25rem;
  display: none;
  margin-bottom: 0.875rem;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 11rem 4.25rem 1fr 11% 12% 16%;
    grid-gap: 1rem;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 11rem 4.25rem 1fr 11% 12% 13%;
  }
}
</style>
