import decode from "jwt-decode";
import SET_AUTHENTICATION from "@/state/mutations/SetAuthentication.graphql";
import { apolloProvider } from "@/common/plugins/apollo";

export default class AuthService {
  static setAuthStatus() {
    apolloProvider.defaultClient.mutate({
      mutation: SET_AUTHENTICATION,

      variables: {
        status: this.isAuthenticated(),
      },
    });
  }

  static isAuthenticated() {
    const token = localStorage.getItem("accessToken");

    if (token && !this.isTokenExpired(token)) {
      return true;
    }

    return false;
  }

  static getTokenExpirationDate(token) {
    const decoded = decode(token);

    if (!decoded.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  static isTokenExpired(token) {
    const date = this.getTokenExpirationDate(token);

    if (date === null) {
      return false;
    }

    return !(date.valueOf() > new Date().valueOf());
  }
}
