<template>
  <div>
    <b-progress
      class="onboarding-progress"
      :class="{'no-sidebar': !showSidebar}"
      variant="info"
      height="5px"
      :value="parseInt(stepNumber, 10)"
      :max="parseInt(totalSteps, 10)"
    />

    <b-card class="onboarding h-100" :class="{'no-sidebar': !showSidebar}">
      <div v-if="showSidebar" class="onboarding-sidebar">
        <app-logo class="mb-40" />
        <div class="text-uppercase text-muted mb-40">
          Step {{ stepNumber }} / {{ totalSteps }}
        </div>
        <h3 class="mb-40">{{ title }}</h3>
        <div class="text-muted">{{ desc }}</div>
      </div>

      <div class="onboarding-content">
        <slot />
      </div>
    </b-card>
  </div>
</template>

<script>
import AppLogo from "./AppLogo";

export default {
  name: "AppOnboarding",

  components: {
    AppLogo,
  },

  props: {
    showSidebar: {
      type: Boolean,
      default: true,
    },

    stepNumber: String,

    totalSteps: String,

    title: String,

    desc: String,
  },
};
</script>


<style lang="scss">
$sidebar-width: 23rem;

.onboarding {
  .card-body {
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0;

    @include media-breakpoint-up(lg) {
      grid-template-columns: $sidebar-width 1fr;
    }
  }

  &.no-sidebar {
    .card-body {
      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr;
      }
    }
  }
}

.onboarding-sidebar {
  padding: 2.5rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    border-right: 1px solid $gray-200;
  }
}

.onboarding-content {
  padding: 2.5rem 2.5rem 5rem;
  font-size: 1.0625rem;

  @include media-breakpoint-up(lg) {
    padding: 2.5rem 15% 5rem;
  }
}

.onboarding-progress {
  border-radius: 0 !important;

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
  }

  &.no-sidebar {
    padding-left: 0;
  }
}
</style>
