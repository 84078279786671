<template>
  <div class="skill-score mb-30" :style="style">
    {{ score ? score : "?" }}
  </div>
</template>

<script>
export default {
  name: "SkillScore",

  props: {
    score: {
      required: true,
      default: null,
    },
  },

  computed: {
    style() {
      let color;
      let backgroundColor;

      if (this.score === null) {
        color = "#97a8ba";
        backgroundColor = "#f5f6fa";
      } else if (this.score < 40) {
        color = "#b99c00";
        backgroundColor = "#f9f4d7";
      } else if (this.score >= 40 && this.score < 65) {
        color = "#1e92a2";
        backgroundColor = "#daeef1";
      } else if (this.score >= 65) {
        color = "#678912";
        backgroundColor = "#e6efd5";
      }

      return {
        color,
        backgroundColor,
      };
    },
  },
};
</script>

<style lang="scss">
.skill-score {
  margin: 0 auto;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7rem;
  background-color: $gray-100;
  font-size: 2rem;
  font-weight: $font-weight-bold;
  color: $gray-500;
  line-height: 1;
}
</style>
