<template>
  <app-layout contentBackground="bg-white">
    <b-container>
      <b-row>
        <b-col md="12">

          <!-- Headings -->
          <section class="mb-50">
            <h1 class="mb-50">Headings</h1>

            <h1>h1. Heading Font Size</h1>
            <h2>h2. Heading Font Size</h2>
            <h3>h3. Heading Font Size</h3>
            <h4>h4. Heading Font Size</h4>
            <h5>h5. Heading Font Size</h5>
            <h6>h6. Heading Font Size</h6>
          </section>

          <hr />

          <!-- Body Text -->
          <section class="mb-50">
            <h1 class="my-50">Body Text</h1>

            <h5>Normal</h5>
            <p class="mb-50">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
            </p>

            <h5>Muted</h5>
            <p class="text-muted mb-50">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
            </p>

            <h5>Lead</h5>
            <p class="lead mb-50">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
            </p>
          </section>

          <hr />

          <!-- Buttons -->
          <section class="mb-50 section-buttons">
            <h1 class="my-50">Buttons</h1>

            <div class="mb-50">
              <h5 class="mb-40">Default</h5>

              <b-button variant="primary">Primary</b-button>
              <b-button variant="secondary">Secondary</b-button>
              <b-button variant="success">Success</b-button>
              <b-button variant="danger">Danger</b-button>
              <b-button variant="warning">Warning</b-button>
              <b-button variant="info">Info</b-button>
              <b-button variant="dark">Dark</b-button>
            </div>

            <div class="mb-50">
              <h5 class="mb-40">Outline</h5>

              <b-button variant="outline-primary">Primary</b-button>
              <b-button variant="outline-secondary">Secondary</b-button>
              <b-button variant="outline-success">Success</b-button>
              <b-button variant="outline-danger">Danger</b-button>
              <b-button variant="outline-warning">Warning</b-button>
              <b-button variant="outline-info">Info</b-button>
              <b-button variant="outline-dark">Dark</b-button>
            </div>

            <div class="mb-50">
              <h5 class="mb-40">Loading State &amp; Icons</h5>

              <b-button variant="primary" class="loading">Primary</b-button>
              <b-button variant="outline-secondary" class="loading">Primary</b-button>
              <b-button variant="primary">
                <app-icon name="icon-trash" />
                Delete
              </b-button>
              <b-button variant="outline-primary">
                <app-icon name="icon-trash" />
                Delete
              </b-button>
            </div>

            <div class="mb-50">
              <h5 class="mt-30">Sizes</h5>

              <b-button variant="primary" size="sm">Small</b-button>
              <b-button variant="primary">Default</b-button>
              <b-button variant="primary" size="lg">Large</b-button>
            </div>
          </section>

          <!-- Forms -->
          <section class="mb-50">
            <h1 class="my-50">Forms</h1>

            <b-row>
              <b-col md="5">
                <b-form-group label="Default">
                  <b-form-input
                    type="text"
                    placeholder="Placeholder Text"
                  />
                </b-form-group>

                <b-form-group label="Small">
                  <b-form-input
                    size="sm"
                    type="text"
                    placeholder="Placeholder Text"
                  />
                </b-form-group>

                <b-form-group label="Large">
                  <b-form-input
                    size="lg"
                    type="text"
                    placeholder="Placeholder Text"
                  />
                </b-form-group>

                <b-form-group label="text area">
                  <b-form-textarea
                      placeholder="Enter something"
                      :rows="3"
                      :max-rows="6">
                  </b-form-textarea>
                </b-form-group>

                <b-form-group label="Checkboxes">
                  <b-form-checkbox value="me">Check me out</b-form-checkbox>
                  <b-form-checkbox value="that">Check that out</b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="5">
                <b-form-group label="Success">
                  <b-form-input
                    :state="true"
                    type="text"
                    placeholder="Placeholder Text"
                  />
                </b-form-group>

                <b-form-group label="Error">
                  <b-form-input
                    :state="false"
                    type="text"
                    placeholder="Placeholder Text"
                    required
                  />
                  <b-form-invalid-feedback>Please provide a valid email</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </section>

          <!-- Alerts -->
          <section class="mb-50">
            <h1 class="my-50">Alerts</h1>

            <b-alert variant="success" show>
              You successfully read this important alert message.
            </b-alert>
            <b-alert variant="info" show>
              Change a few things up and try submitting again.
            </b-alert>
            <b-alert variant="warning" show>
              Change a few things up and try submitting again.
            </b-alert>
            <b-alert variant="danger" show>
              Change a few things up and try submitting again.
            </b-alert>
          </section>

          <!-- Modal -->
          <section class="mb-50">
            <h1 class="my-50">Modal</h1>

            <div>
              <b-btn v-b-modal.modal1>Launch Modal</b-btn>

              <b-modal
                id="modal1"
                title="Delete Item"
                ok-variant="danger"
                ok-title="Delete"
                cancel-variant="outline-secondary"
                no-fade
              >
                <p>Do you really want to delet this item?</p>
              </b-modal>
            </div>
          </section>

          <!-- Dropdowns -->
          <section class="mb-50">
            <h1 class="my-50">Dropdowns</h1>

            <b-dropdown text="Dropdown" variant="outline-secondary">
              <b-dropdown-item>First Action</b-dropdown-item>
              <b-dropdown-item>Second Action</b-dropdown-item>
              <b-dropdown-item>Third Action</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item>Something else here...</b-dropdown-item>
              <b-dropdown-item disabled>Disabled action</b-dropdown-item>
            </b-dropdown>
          </section>

          <!-- Tabs -->
          <section class="mb-50">
            <h1 class="my-50">Tabs</h1>

            <b-tabs
              nav-class="nav-tabs-material"
              content-class="pt-40"
            >
              <b-tab title="Profile" active>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                  euismodtincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                  minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip ex ea commodo consequat.
                </p>
              </b-tab>
              <b-tab title="Preferences" >
                <p>
                  Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
                  lobortisnisl ut aliquip ex ea commodo consequat.adipiscing elit, sed diam nonummy
                  nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </p>
              </b-tab>
              <b-tab title="Settings">
                <p>
                  Vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto
                  odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te
                  feugait nulla facilisi.
                </p>
              </b-tab>
            </b-tabs>
          </section>

          <!-- Date Picker -->
          <section class="mb-50">
            <b-row>
              <b-col md="5">
                <h1 class="my-50">Date Picker</h1>

                <app-datepicker
                  placeholder="Pick a date"
                />
              </b-col>
            </b-row>
          </section>

        </b-col>
      </b-row>
    </b-container>
  </app-layout>
</template>

<script>
import AppDatepicker from "@/components/AppDatepicker";
import AppIcon from "@/components/AppIcon";
import AppLayout from "@/layouts/AppLayout";

export default {
  name: "Components",

  components: {
    AppIcon,
    AppLayout,
    AppDatepicker,
  },
};
</script>

<style lang="scss">
.section-buttons {
  button {
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
</style>
