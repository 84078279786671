<template>
  <app-layout
    :showHeader="false"
    :showSidebar="false"
  >
    <div class="not-found">
      <b-container>
        <b-row>
          <b-col class="text-center">
            <h1>404</h1>
            <p class="not-found-text">Not Found</p>
            <p class="lead my-40">Ooops! We can't seem to find the page you're looking for.</p>
            <b-button variant="primary" to="/" exact>Go to Home Page</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
  name: "NotFound",

  components: {
    AppLayout,
  },
};
</script>

<style lang="scss">
.not-found {
  padding-top: 4rem;

  h1 {
    font-size: 10rem;
    font-weight: 900;
  }

  .not-found-text {
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.75rem;
    padding-left: 0.625rem;
  }
}
</style>
