<template>
  <div>
    <h4 class="mb-20">Your Match</h4>

    <b-card
      class="job-user-match p-20"
      no-body
    >
      <semi-circle-gauge :value="matchPercentage" />
      <p class="pl-20 mb-0 align-self-center">
        <span class="text-dark">Tip:</span>
        <span class="text-muted">You can increase your match by adding skills asked for this job and validating them in tests page.</span>
      </p>
    </b-card>
  </div>
</template>

<script>
import SemiCircleGauge from "./SemiCircleGauge";

export default {
  name: "JobUserMatch",

  components: {
    SemiCircleGauge,
  },

  props: {
    matchPercentage: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.job-user-match {
  flex-direction: row !important;
  justify-content: space-between;
}
</style>
