<template>
  <b-form-radio-group
    buttons
    class="action-buttons"
    button-variant="outline-secondary"
    :options="options"
    v-model="selected"
    @change="onChange"
  />
</template>

<script>
export default {
  name: "ActionButtons",

  props: {
    actionResponse: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selected: this.actionResponse,
      options: [
        { text: "Interested", value: "yes" },
        { text: "Not interested", value: "no" },
      ],
    };
  },

  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss">
.action-buttons {
  label {
    &.active {
      border-color: theme-color("success") !important;
      background-color: theme-color("success") !important;
      color: $white !important;
    }

    &:last-child {
      &.active {
        border-color: theme-color("danger") !important;
        background-color: theme-color("danger") !important;
        color: $white !important;
      }
    }
  }
}
</style>
