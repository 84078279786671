<template>
  <app-layout>
    <b-container>
      <app-page-title
        icon="icon-checkmark-square"
        title="My Tests"
      />

      <div class="tests-page">
        <b-nav tabs class="nav-tabs-material mb-30">
          <b-nav-item :to="{ name: 'SkillTests' }" exact>
            Skills
          </b-nav-item>

          <b-nav-item :to="{ name: 'LogicalAbilityTests' }">
            Logical Ability
          </b-nav-item>
        </b-nav>

        <transition
            enter-active-class="animated page-enter"
            leave-active-class="animated page-leave"
            mode="out-in"
          >
          <router-view />
        </transition>
      </div>
    </b-container>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import AppPageTitle from "@/components/AppPageTitle";

export default {
  name: "TestsIndex",

  components: {
    AppLayout,
    AppPageTitle,
  },
};
</script>
