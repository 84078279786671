<template>
  <div class="skill-validation-status">
    <!-- Validated -->
    <div
      v-if="skill.validationStatus === 'validated'"
      class="skill-validated text-success"
    >
      <app-icon name="icon-checkmark" size="20px" />
      <span>Validated</span>
    </div>

    <!-- Failed -->
    <div
      v-else-if="skill.validationStatus === 'failed'"
      class="skill-failed text-muted"
    >
      <app-icon name="icon-checkmark" size="20px" />
      <span>Completed</span>
    </div>

    <!-- Not Started -->
    <b-button
      v-else-if="skill.validationStatus === 'notStarted'"
      :variant="buttonVariant"
      :size="buttonSize"
      :block="block"
      :to="{ name: 'SkillTestIntro', params: { skillId: skill.id, skill } }"
    >
      <app-icon name="icon-checkmark" />
      <span>Validate Now</span>
    </b-button>

    <!-- Started -->
    <b-button
      v-else-if="skill.validationStatus === 'started'"
      :variant="buttonVariant"
      :size="buttonSize"
      :block="block"
      :to="{ name: 'SkillTest', params: { skillId: skill.id, skill } }"
    >
      <app-icon name="icon-refresh" size="20px" />
      <span>Resume Test</span>
    </b-button>

    <!-- Not Available -->
    <div
      v-else-if="skill.validationStatus === 'notAvailable'"
      class="skill-coming-soon text-muted"
      disabled
      block
    >
      <app-icon name="icon-hourglass-empty" />
      <span>Coming Soon</span>
    </div>


    <div v-else>&nbsp;</div>
  </div>

</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "SkillCardFooter",

  components: {
    AppIcon,
  },

  props: {
    skill: {
      type: Object,
      required: true,
    },

    buttonVariant: {
      type: String,
      default: "outline-primary",
    },

    buttonSize: {
      type: String,
      default: "",
    },

    block: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.skill-validation-status {
  .skill-validated,
  .skill-failed,
  .skill-coming-soon {
    height: $app-btn-height;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-base;
    font-weight: $btn-font-weight;

    span {
      display: inline-block;
      margin-left: 2px;
    }
  }
}
</style>
