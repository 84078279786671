<template>
  <b-card class="job-status-activity">
    <h4 class="mb-20">Latest Activity</h4>

    <div class="latest-activity-items">
      <job-latest-activity-item
        class="mb-20"
        v-for="activity in activityFeed"
        :key="activity.id"
        :activity="activity"
      />
    </div>
  </b-card>
</template>

<script>
import JobLatestActivityItem from "./JobLatestActivityItem";

export default {
  name: "JobLatestActivity",

  props: {
    activityFeed: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  components: {
    JobLatestActivityItem,
  },
};
</script>

<style lang="scss">
.latest-activity-items {
  position: relative;

  &::before {
    position: absolute;
    top: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
    display: block;
    width: 1px;
    background-color: $gray-300;
    content: "";
  }
}
</style>
