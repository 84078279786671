import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import VueAnalytics from "vue-analytics";
import decode from "jwt-decode";
import * as Sentry from "@sentry/browser";
import router from "../../router";

export default function registerPlugins() {
  Vue.use(BootstrapVue);
  Vue.use(Vuelidate);
  Vue.use(Notifications);

  const isProd = process.env.NODE_ENV === "production";

  // Google Analytics
  Vue.use(VueAnalytics, {
    id: "UA-125571061-1",
    router,
    debug: {
      // enabled: !isProd,
      sendHitTask: isProd,
    },
  });

  // Sentry

  const sentryEnvironments = ["production", "staging"];

  if (sentryEnvironments.includes(process.env.NODE_ENV)) {
    const accessToken = localStorage.getItem("accessToken");
    let email = "";

    if (accessToken !== null) {
      const decodedToken = decode(accessToken);

      if (decodedToken) {
        email = decodedToken.username
      }
    }


    Sentry.init({
      dsn: "https://d48c60aef10e44749be39657b2c9d76e@sentry.io/1280571",
      integrations: [new Sentry.Integrations.Vue({ Vue })],
      environment: process.env.NODE_ENV,
    });

    // needs to come after init, set custom settings here
    Sentry.configureScope(scope => {
      scope.setUser({ "email": email, "token": accessToken });
    });

  }
}

export { apolloProvider } from "./apollo";
