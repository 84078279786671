<template>
  <b-form-select
    v-model="selected"
    :options="options"
    :size="size"
    @input="onInput"
  />
</template>

<script>
import ALL_LOCATIONS from "@/graphql/queries/AllLocations.graphql";

export default {
  name: "AppCountrySelect",

  props: {
    nationality: {
      type: Boolean,
      default: false,
    },

    value: [Number, String],

    size: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      selected: this.value,
      allLocations: [],
    };
  },

  computed: {
    options() {
      const placeholder = this.nationality
        ? "Please Select Your Nationality"
        : "Please Select Your Location";

      const countryList = this.allLocations.map(country => ({
        value: country.id,
        text: this.nationality
          ? `${country.nationality} (${country.name})`
          : country.name,
      }));

      return [{ value: null, text: placeholder }].concat(countryList);
    },
  },

  apollo: {
    allLocations: {
      query: ALL_LOCATIONS,
    },
  },

  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>
