import Vue from "vue";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";

import { createStateLink } from "@/state";
import { authLink } from "./links/auth";
import { errorLink } from "./links/error";
import { httpLink } from "./links/http";

const cache = new InMemoryCache();
const stateLink = createStateLink(cache);
const link = ApolloLink.from([stateLink, authLink, errorLink, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: process.env.NODE_ENV !== "production",
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(VueApollo);
