<template>
  <div
    class="avatar"
    :style="styles"
  >
    <span
      v-if="!image"
      class="initials"
    >
      {{ userInitials }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AppAvatar",

  props: {
    image: String,

    size: {
      type: String,
      default: "2.25rem",
    },

    square: {
      type: Boolean,
      default: false,
    },

    firstName: {
      type: String,
      required: true,
    },

    lastName: {
      type: String,
      required: true,
    },
  },

  computed: {
    userInitials() {
      return `${this.firstName.substr(0, 1)}${this.lastName.substr(0, 1)}`;
    },

    styles() {
      const colors = [
        { bg: "#e6efd5", color: "#74991c" },
        { bg: "#d2e9ec", color: "#107f82" },
        { bg: "#f9ecd7", color: "#c47d14" },
        { bg: "#fcdbdb", color: "#d33333" },
      ];

      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      const borderRadius = this.square ? "3px" : this.size;

      const styles = {
        borderRadius,
        width: this.size,
        height: this.size,
        minWidth: this.size,
        minHeight: this.size,
        backgroundSize: "cover",
        backgroundColor: randomColor.bg,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        color: randomColor.color,
      };

      if (this.image) {
        styles.backgroundImage = `url(${this.image})`;
      }

      return styles;
    },
  },
};
</script>

<style lang="scss">
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .initials {
    text-transform: uppercase;
    font-size: $font-size-sm;
  }
}
</style>
