<template>
  <b-dropdown
    class="user-menu"
    variant="link"
    right
  >
    <template slot="button-content">
      <app-avatar
        :image="user.candidateprofile.image"
        :firstName="user.firstName"
        :lastName="user.lastName"
      />
      <span class="username ml-10">{{ user.firstName }} {{ user.lastName }}</span>
    </template>

    <b-dropdown-item class="dropdown-item-icon" :to="{ name: 'Profile' }">
      <app-icon name="icon-user" />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-item class="dropdown-item-icon"
      @click="logout"
    >
      <app-icon name="icon-logout" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import auth from "@/common/auth/authMixin";
import AppIcon from "@/components/AppIcon";
import AppAvatar from "@/components/AppAvatar";

export default {
  name: "UserMenu",

  components: {
    AppAvatar,
    AppIcon,
  },

  mixins: [auth],

  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.user-menu {
  .btn-link {
    color: $gray-700 !important;
    font-size: $font-size-base;
  }

  .username {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
</style>
