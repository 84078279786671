<template>
  <app-layout
    :showHeader="false"
    :showFooter="false"
    :showSidebar="false"
  >
    <b-container>
      <transition
        enter-active-class="animated page-enter"
        leave-active-class="animated page-leave"
        mode="out-in"
      >
        <keep-alive>
          <component
            v-if="!error"
            :is="onboardingStep"
            :userData="user"
            @submit="onSubmit"
            @skill-update="onSkillUpdate"
          />
        </keep-alive>
      </transition>
    </b-container>
  </app-layout>
</template>

<script>
import { merge } from "lodash";
import VERIFY_UUID from "@/graphql/queries/VerifyUuid.graphql";
import ONBOARDING_STEP from "@/state/queries/OnboardingStep.graphql";
import AppLayout from "@/layouts/AppLayout";
import Introduction from "./Introduction";
import BasicInformation from "./BasicInformation";
import SocialLinks from "./SocialLinks";
import Skills from "./Skills";
import Password from "./Password";
import OnboardingFinal from "./OnboardingFinal";

export default {
  name: "Onboarding",

  components: {
    AppLayout,
    Introduction,
    BasicInformation,
    SocialLinks,
    Skills,
    Password,
    OnboardingFinal,
  },

  data() {
    return {
      onboardingStep: "",
      error: true,
      user: {},
    };
  },

  created() {
    this.$apollo.addSmartQuery("onboardingStep", {
      query: ONBOARDING_STEP,
    });
  },

  apollo: {
    verifyUuid: {
      query: VERIFY_UUID,

      variables() {
        return {
          uuid: this.$route.params.id,
        };
      },

      update({ verifyUuid }) {
        if (!verifyUuid) {
          this.$router.replace("/login");
        } else {
          this.error = false;
        }
      },
    },
  },

  methods: {
    setCurrentStep(componentName) {
      this.currentStep = componentName;
    },

    onSubmit(data) {
      this.user = merge(this.user, data);
    },

    onSkillUpdate(data) {
      this.user = merge(data, this.user);
    },
  },
};
</script>

<style lang="scss">
.onboarding-container {
  @include media-breakpoint-up(sm) {
    margin-top: 2rem;
  }
}
</style>
