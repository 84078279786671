<template>
  <div>
    <div
      v-if="!currentUser"
      class="d-flex justify-content-center my-40"
    >
      <app-spinner />
    </div>

    <div
      v-else
      class="skill-tests"
    >
      <b-row>
        <b-col>
          <div class="skill-tests-grid mb-50">
            <skill-card
              v-for="skill in skills"
              :key="skill.id"
              :skill="skill"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CURRENT_USER from "@/graphql/queries/CurrentUser.graphql";
import AppSpinner from "@/components/AppSpinner";
import SkillCard from "./SkillCard";

export default {
  name: "SkillTests",

  components: {
    AppSpinner,
    SkillCard,
  },

  data() {
    return {
      skills: [],
    };
  },

  apollo: {
    currentUser: {
      query: CURRENT_USER,

      fetchPolicy: "network-only",

      update({ currentUser }) {
        if (currentUser) {
          this.skills = currentUser.candidateprofile.skills.map(skill => ({
            id: skill.id,
            name: skill.name,
            validationStatus: skill.validationStatus,
            score: skill.score,
          }));
        }

        return currentUser;
      },
    },
  },
};
</script>

<style lang="scss">
.skill-tests-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(auto-fill, minmax(15rem, auto));
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(auto-fill, minmax(16.25rem, auto));
  }
}
</style>
