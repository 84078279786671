<template>
  <b-card class="mb-50">
    <b-row>
      <b-col lg="10" offset-lg="1">

        <!-- Overall Score -->
        <div class="logical-ability-overall mb-30 mt-lg-50 mb-lg-30">
          <div class="score-container mb-30">
            <div class="score text-success mb-5">{{ test.overall.score }}</div>
            <div>Overall Score</div>
          </div>

          <div>
            <h3 class="mb-20">Overall Score</h3>
            <p class="score-text">{{ test.overall.text | interpolateResultText(user.fullName, user.firstName, test.overall.score) }}</p>
          </div>
        </div>

        <hr />

        <!-- Details -->
        <logical-test-result
          v-for="subTest in test.detail"
          :key="subTest.name"
          :test="subTest"
          :variant="subTest.variant"
          :user="user"
        />

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import LogicalTestResult from "./LogicalTestResult";

export default {
  name: "TestCompleted",

  components: {
    LogicalTestResult,
  },

  props: {
    test: {
      type: Object,
      required: true,
    },

    user: Object,
  },
};
</script>

<style lang="scss">
.logical-ability-overall {
  display: grid;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 12rem 1fr;
    grid-gap: 2rem;
  }

  .score-container {
    padding: 1rem;
    border: 1px solid $gray-200;
    text-align: center;
  }

  .score {
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1;
  }
}
</style>
